import React, { useState, useEffect } from "react";
import CommonServives from "utils/services/api/common";
import { master_data } from "utils/services/api/schemas";
import history from "utils/history";

export default function FilterFunction() {
  const [masterData, setMasterData] = useState({});
  const [searchParams, setSeachPatams] = useState({});

  const { getList } = CommonServives();

  useEffect(() => {
    GetMasterData();
  }, [searchParams]);

  function GetMasterData() {
    getList(master_data)
      .then((response) => {
        // let data = response.data
        // data.location = [{
        //   name: 'All',
        //   status: 'ACTIVE',
        // }, ...data.location]
        // data.event = [{
        //   name: 'All',
        //   status: 'ACTIVE',
        // }, ...data.event]

        // data.people = [{
        //   number_of_people: "All",
        //   status: 'ACTIVE',
        // }, ...data.max_people]

        setMasterData(response.data);
      })
      .catch((err) => {
        alert(err);
      });
  }
  function onSelectLocation(item) {
    setSeachPatams({ ...searchParams, location: item.name });
  }

  function onSelectEvent(item) {
    setSeachPatams({ ...searchParams, event: item.name });
  }

  function onSelectPeople(item) {
    setSeachPatams({ ...searchParams, max_people: item.name });
  }

  function onSelectDate(date) {
    setSeachPatams({ ...searchParams, selected_date: date });
  }

  function generateSearchParams() {
    let params = Object.keys(searchParams).map((key) => `${key}=${searchParams[key]}`);
    return params.join("&");
  }
  function generateSearchParamsWithData(data) {
    let params = Object.keys(data).map((key) => `${key}=${data[key]}`);
    return params.join("&");
  }

  function getParams() {
    const searchParams = new URLSearchParams(history.location.search);
    const params = {};
    for (const [key, value] of searchParams.entries()) {
      params[key] = value;

      if (key === "event") {
        params[key] = params[key].replaceAll("-", "&");
        params[key] = params[key].replaceAll("_", "|");
      }
    }
    return params;
  }

  function getParamsDump(keyParam) {
    const searchParams = new URLSearchParams(history.location.search);
    let values = [];
    for (const [key, value] of searchParams.entries()) {
      if (key === keyParam) {
        values.push(value);
      }
    }
    return values;
  }

  function compareAndUpdateObjects(obj1, obj2) {
    // loop through each property of obj1
    for (let prop in obj1) {
      // check if the property exists in obj2
      if (obj2.hasOwnProperty(prop)) {
        // if the property is an object, recursively compare and update it
        if (typeof obj1[prop] === "object") {
          compareAndUpdateObjects(obj1[prop], obj2[prop]);
        } else {
          // if the property is a primitive type, compare and update the value
          if (obj1[prop] !== obj2[prop]) {
            obj1[prop] = obj2[prop];
          }
        }
      }
    }

    // loop through each property of obj2
    for (let prop in obj2) {
      // check if the property exists in obj1
      if (!obj1.hasOwnProperty(prop)) {
        // if the property does not exist in obj1, add it
        obj1[prop] = obj2[prop];
      }
    }

    // return the updated object as a JSON string
    return obj1;
  }

  return {
    masterData,
    searchParams,
    onSelectLocation,
    onSelectEvent,
    onSelectPeople,
    onSelectDate,
    generateSearchParams,
    getParams,
    setSeachPatams,
    compareAndUpdateObjects,
    generateSearchParamsWithData,
    getParamsDump,
  };
}
