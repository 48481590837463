import { useState, useEffect } from 'react'
import CommonServives from 'utils/services/api/common'
import { master_data } from 'utils/services/api/schemas'

export default function HomeFunction() {
  const [masterData, setMasterData] = useState({})
  const { getList } = CommonServives()

  useEffect(() => {
    GetMasterData()
  }, [])

  function GetMasterData() {
    getList(master_data)
      .then((response) => {
        setMasterData(response.data)
      })
      .catch((err) => {
        alert(err)
      })
  }

  return { masterData }
}
