import React, { useState } from 'react'
import './style.css'

export default function AccordionItem({ children, number, title, onClick, index, selectIndex }) {
  return (
    <div className={`accordion-item ${index === selectIndex ? '' : ''}`}>
      <div className="accordion-click" onClick={onClick ? onClick : () => {}}>
        <div className="number">{number}</div>
        <div className="accordion-head">
          <div>{title}</div>
          <div className="toggle">
            <div className="toggle-t" style={{ display: index === selectIndex ? 'none' : 'block' }}></div>
            <div className="toggle-n"></div>
          </div>
        </div>
      </div>
      <div
        className={`accordion-content`}
        style={{ maxHeight: index === selectIndex ? '300px' : '0px', transition: index === selectIndex ? '1.0s ease' : '0.35s ease' }}
      >
        <p>{children}</p>
      </div>
    </div>
  )
}
