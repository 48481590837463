import React, { useState, useEffect, useRef } from 'react'
import './style.css'

function DateRangPicker({ options, placeholder = '', onSelect, value, style }) {
  // const [selectedItem, setSelectedItem] = useState(options && options[0])
  const [isExpanded, setIsExpanded] = useState(false)
  const [clickedInside, setClickedInside] = useState(false)
  const inSideRef = useRef()

  const handleItemClick = (item) => {
    setIsExpanded(false)

    if (!onSelect) return
    onSelect(item)
  }

  const toggleSelect = () => {
    setIsExpanded(!isExpanded)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inSideRef.current && !inSideRef.current.contains(event.target)) {
        setClickedInside(false)
        setIsExpanded(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [inSideRef])

  return (
    <div className="date-rang-custom" ref={inSideRef} onClick={() => (options.length > 0 ? setClickedInside(true) : () => {})}>
      <div
        className={`select-selected-rang ${options.length === 0 ? 'selected-disabled' : ''}`}
        onClick={() => (options.length > 0 ? toggleSelect() : () => {})}
        style={style}
      >
        {value && value.name ? value.name : placeholder}
      </div>
      {clickedInside && isExpanded && (
        <div className="select-items" style={{ top: 'unset' }}>
          {options.map(
            (option, index) =>
              option && (
                <div
                  key={index}
                  className={
                    option.disabled ? 'disabled-selected' : value && value.name ? (option.name === value.name ? 'same-as-selected' : '') : ''
                  }
                  onClick={option.disabled ? () => {} : () => handleItemClick(option)}
                >
                  {option && option.name}
                </div>
              )
          )}
        </div>
      )}
    </div>
  )
}

export default DateRangPicker
