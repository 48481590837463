import React, { useEffect, useRef, useState } from 'react'
import { event_data } from 'utils/services/api/schemas'
import CommonServives from 'utils/services/api/common'

export default function SpaceMomentFunction() {
  const [events, setEvents] = useState([])

  const { getList } = CommonServives()

  useEffect(() => {
    getEvents()
  }, [])

  function getEvents() {
    getList(event_data)
      .then((response) => {
        let data = response.data
        data = data.sort((a, b) => {
          return b.count - a.count
        })
        data = data.slice(0, 6)
        setEvents(data)
      })
      .catch((err) => {
        alert(err)
      })
  }
  return { events }
}
