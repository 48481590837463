import React, { useState } from "react";

export default function HeroBannerFunction(props) {
  const [searchParams, setSeachPatams] = useState(null);

  function onSelectLocation(item) {
    setSeachPatams({ ...searchParams, location: item.value ? item.value : "" });
  }

  function onSelectEvent(item) {
    setSeachPatams({ ...searchParams, event: item.value ? item.value : "" });
  }

  function onSelectPeople(item) {
    setSeachPatams({ ...searchParams, max_people: item.value ? item.value : "" });
  }

  function onSelectDate(date) {
    setSeachPatams({ ...searchParams, selected_date: date ? date : "" });
  }

  function generateSearchParams() {
    if (searchParams.event) {
      let event_params = searchParams;
      event_params.event = event_params.event.replaceAll("|", "_");
      event_params.event = event_params.event.replaceAll("&", "-");

      // setSeachPatams(event_params);
      searchParams.event = event_params.event;
    }

    let params = Object.keys(searchParams).map((key) => `${key}=${searchParams[key]}`);
    return params.join("&");
  }

  return { searchParams, onSelectLocation, onSelectEvent, onSelectPeople, onSelectDate, generateSearchParams };
}
