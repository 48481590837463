import React from "react";
import "assets/css/header_footer.css";
import startMenu from "./utils/menu";
import { Modal } from "antd";
import FilterComponent from "../filter";
import useFunction from "./function";
import "./style.css";
import { LineShareButton } from "react-share";

export default function NavbarSpaceComponent({ reqDetail = true, disableBg = false }) {
  const [openMenu, setOpenMenu] = React.useState(false);
  const [openSearch, setOpenSearch] = React.useState(false);
  const { getParams } = useFunction();
  const [params, setParams] = React.useState({});
  const [updateModel, setUpdateModel] = React.useState(true);

  const listImages = [
    "/assets/images/picture_menu_random/1.jpg",
    "/assets/images/picture_menu_random/2.jpg",
    "/assets/images/picture_menu_random/3.jpg",
    "/assets/images/picture_menu_random/4.jpg",
    "/assets/images/picture_menu_random/5.jpg",
    "/assets/images/picture_menu_random/6.jpg",
    "/assets/images/picture_menu_random/7.jpg",
    "/assets/images/picture_menu_random/8.jpg",
    "/assets/images/picture_menu_random/9.jpg",
    "/assets/images/picture_menu_random/10.jpg",
  ];
  function openSearchFilter(stateModal, updateMode) {
    setUpdateModel(updateMode);
    setOpenSearch(stateModal);
  }
  React.useEffect(() => {
    startMenu();
    setParams(getParams());
  }, []);

  return (
    <header className={`header_space ${!disableBg ? "bg-navbar" : ""}`}>
      <div className="inner">
        <div className="col">
          <div className="logo_main">
            <a href={`/`} aria-label="gu-home">
              <img src="../../assets/images/logo-gu-space.svg" alt="" />
            </a>
          </div>
        </div>
        {reqDetail ? (
          <div className="col">
            <div className="event is-show">
              <p style={{ textTransform: "capitalize" }}>
                {params.event && <span className="txtbold">{params.event}</span>}
                {params.max_people && (
                  <span>
                    {" "}
                    for <span className="txtbold">{params.max_people} people</span>
                  </span>
                )}
                {params.location && (
                  <span>
                    {" "}
                    in <span className="txtbold"> {params.location}</span>
                  </span>
                )}
                {params.selected_date && (
                  <span>
                    {" "}
                    on <span className="txtbold">{params.selected_date}</span>
                  </span>
                )}
              </p>

              {params.event || params.max_people || params.location || params.selected_date ? "" : <span className="txtbold">All Spaces</span>}
              <button
                onClick={(e) => {
                  openSearchFilter(true, true);
                }}
                data-modal-target="searchModal"
                data-modal-toggle="searchModal"
              >
                Change
              </button>
            </div>
          </div>
        ) : (
          <div className="col"></div>
        )}
        <div className="col">
          <div
            onClick={(e) => {
              openSearchFilter(true, false);
            }}
            className="btn_search"
            data-modal-target="searchModal"
            data-modal-toggle="searchModal"
          >
            <img src="../../assets/images/btn_search.svg" alt="" />
          </div>
          <div
            onClick={(e) => {
              setOpenMenu(true);
            }}
            className="btn_hamburger"
          >
            <img src="../../assets/images/btn_hamburger.svg" alt="" />
          </div>
        </div>
      </div>

      {openMenu && (
        <Modal centered open={openMenu} width="100%" height="100%" closableProps={{ style: { display: "none" } }} okButtonProps={{ style: { display: "none" } }} cancelButtonProps={{ style: { display: "none" } }}>
          <div className="expand active">
            <div className="txt">
              <div className="txt_inner">
                <ul>
                  <li>
                    <a href="/" id="menu_home">
                      Home
                    </a>
                  </li>
                  <li>
                    <a href="/about" id="menu_all_about">
                      About
                    </a>
                  </li>
                  <li>
                    <a href="/gallery" id="menu_gallery">
                      Gallery
                    </a>
                  </li>
                  <li>
                    <a href="/spaces" id="menu_all_spaces">
                      ALL Spaces
                    </a>
                  </li>
                  <li>
                    <a href="/contact" id="menu_contact">
                      CONTACT
                    </a>
                  </li>
                </ul>

                <div className="social">
                  <a target="_blank" href="https://www.facebook.com/guspacebkk">
                    <img src="/assets/images/social/fb_bw.svg" alt="" aria-label="facebook" />
                  </a>
                  <a target="_blank" href="https://www.tiktok.com/@guspacebkk">
                    <img src="/assets/images/social/tiktok.svg" alt="" aria-label="tiktok" />
                  </a>
                  <a href="https://www.instagram.com/guspace_bkk" target="_blank">
                    <img src="/assets/images/social/ig_bw.svg" alt="" aria-label="ig" />
                  </a>
                  <a href="https://lin.ee/8pqDcBM" target="_blank">
                    <img src="/assets/images/social/line_bw.svg" alt="" aria-label="line" />
                  </a>
                </div>
                <div className="copyright">
                  <p>&copy; 2023 GU SPACE. Registered in Thailand. Company Registration Number 13324675. VAT Number 3795673330. Address: 18 Golden Road, Bangkok Thailand, 10900 Privacy Policy</p>
                </div>
              </div>
            </div>

            <div className="img">
              <img src={listImages[Math.floor(Math.random() * 10)]} alt="" />
            </div>

            <button
              onClick={(e) => {
                setOpenMenu(false);
              }}
              className="btn_close_menu"
            >
              <img src="/assets/images/btn_close_menu.svg" alt="" />
            </button>
          </div>
        </Modal>
      )}
      {openSearch && (
        <Modal className="custom-modal wrap_modal" centered open={openSearch} onOk={() => setOpenSearch(false)} closable={false} okButtonProps={{ style: { display: "none" } }} cancelButtonProps={{ style: { display: "none" } }} width={600}>
          <div className="modal_content relative bg-ci-guspace shadow">
            <div className="modal_header flex items-center justify-between">
              <h3 className="font-bold font-noto">SEARCH</h3>
              <button
                onClick={(e) => {
                  setOpenSearch(false);
                }}
                type="button"
                className="inline-flex items-center"
                data-modal-hide="searchModal"
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2 2L22.4043 22.4044" stroke="black" stroke-width="4"></path>
                  <path d="M22.4043 2L1.99991 22.4043" stroke="black" stroke-width="4"></path>
                </svg>
              </button>
            </div>
            <div className="modal_body" style={{ height: "auto", padding: "40px 24px" }}>
              {openSearch ? <FilterComponent updateMode={updateModel} /> : ""}
            </div>
          </div>
        </Modal>
      )}
    </header>
  );
}
