import axios from 'axios'

export default function HttpClient() {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 10000,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${typeof window !== 'undefined' && localStorage.getItem('token')}`,
    },
  })

  instance.interceptors.response.use(
    (response) => response,
    (err) => {
      return err
    }
  )

  const httpClient = instance

  return { httpClient }
}
