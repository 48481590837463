import React from 'react'
import './style.css'

export default function LoadginScene({ isLoading }) {
  const currentPath = window.location.pathname; 
  return (
    isLoading && (
      <div>
        <div className={`loading-container ${currentPath === '/'?'loading-container-home':''}` }>
          <span className="loader"></span>
        </div>
      </div>
    )
  )
}
