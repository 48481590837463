import React, { Fragment, useEffect } from 'react'
import defaultImage from '../../../assets/images/default-image.jpg'
import Swiper from 'swiper'
// import { gsap } from 'gsap'

export default function Index({ neighborhood = [] }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [ ])
  let iwin=0
  return (
    neighborhood.length > 0 && (
      <div>
        <section className="visit">
          <div className="txthead text-center">
            <h2>Visit our neighborhood</h2>
            <p>เยี่ยมเยียนเพื่อนบ้านใกล้เคียง</p>
          </div>

          <div className="swiper" id="slide_visit">
            <div className="swiper-wrapper">
              {neighborhood && neighborhood.map((windows, index) => {
                  if(iwin < 3){
                    iwin = iwin + 1
                  }else{
                    iwin = iwin - 2
                  }
                return (
                  <div className="swiper-slide">
                    <div className={`window${iwin}`}>
                      <div className={`window${iwin}_main`}>
                        <div className={`window${iwin}_left`}>
                          <img src={`../../assets/images/window/window${iwin}-left.svg`} alt="" />
                        </div>
                        <div  onClick={()=>{ window.location.href = `/spaces/${windows.id}`}}
                          className={`cursor-pointer window${iwin}_frame`}
                          style={{
                            backgroundSize: '90% 100%',
                            backgroundImage: `url(${windows.images_display ? windows.images_display : 'assets/default.png'})`,
                          }}
                        >
                          <img src={`../../assets/images/window/window${iwin}-main.svg`} alt="" />
                          <img src={window.images_display ? window.images_display[0] :`/assets/images/window${iwin}_img.jpg` } className="window_img_main" alt="" />
                        </div>
                        <div className={`window${iwin}_right`}>
                          <img src={`../../assets/images/window/window${iwin}-right.svg`} alt="" />
                        </div>
                      </div>
                    </div>


                    <div className="txt text-center cursor-pointer" onClick={()=>{ window.location.href = `/spaces/${windows.id}`}} >
                      <h2 className="font-noto font-bold">{windows.space_detail_options}</h2>
                      <p >{windows.name}</p>
                      <p style={{marginTop:'0px'}}>{windows.location}</p>
                      <div className="sub_detail">
                        <div className="item">
                          <img src="../../assets/images/i_baht.svg" alt="" />
                          <span>เริ่มต้นที่ {windows.min_price} บาท/ชม.</span>
                        </div>
                        <div className="item">
                          <img src="../../assets/images/i_search_people.svg" alt="" />
                          <span>{windows.max_people} คน</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>

            {neighborhood.length > 1 && (
              <Fragment>
                <div id="swiperprev" className="swiper-button-prev"></div>
                <div id="swipernext" className="swiper-button-next"></div>
              </Fragment>
            )}
          </div>
        </section>
      </div>
    )
  )
}
