import { useEffect, useState } from 'react'

import gsap from 'gsap'

export default function AboutFunction() {
  useEffect(() => {
    gsap.to('body', {
      duration: 2,
      opacity: 1,
      delay: 1,
      ease: 'power3.out',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {}
}
