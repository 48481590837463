import { useNavigate, useParams } from 'react-router-dom'

export default function BookingFunction() {
  const navigate = useNavigate()
  const { id } = useParams()

  function onClickBooking() {
    navigate(`/spaces/booking/${id}/thankyou`)
  }

  return { onClickBooking }
}
