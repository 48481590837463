import React, { useEffect } from "react";
import NavbarSpaceComponent from "components/global/navbarspace";
import $ from "jquery";
import Isotope from "isotope-layout";

import startAnimation from "utils/animate";

//style and function from them self
import "./style.css";
import useFunction from "./function";

const imageGallery = [
  {
    id: 1,
    filter: "artspace",
    image: "/assets/images/gallery/1.jpg",
    caption: "",
  },
  {
    id: 2,
    filter: "wedding",
    image: "/assets/images/gallery/2.jpg",
    caption: "",
  },
  {
    id: 3,
    filter: "wedding",
    image: "/assets/images/gallery/3.jpg",
    caption: "",
  },
  {
    id: 4,
    filter: "wedding",
    image: "/assets/images/gallery/4.jpg",
    caption: "",
  },
  {
    id: 5,
    filter: "artspace",
    image: "/assets/images/gallery/5.jpg",
    caption: "",
  },
  {
    id: 6,
    filter: "artspace",
    image: "/assets/images/gallery/6.jpg",
    caption: "",
  },
  // {
  //   id: 7,
  //   filter: "workshop",
  //   image: "/assets/images/gallery/7.jpg",
  //   caption: "",
  // },
  {
    id: 8,
    filter: "artspace",
    image: "/assets/images/gallery/8.jpg",
    caption: "",
  },
  {
    id: 9,
    filter: "artspace",
    image: "/assets/images/gallery/9.jpg",
    caption: "",
  },
  {
    id: 10,
    filter: "artspace",
    image: "/assets/images/gallery/10.jpg",
    caption: "",
  },
  {
    id: 11,
    filter: "wedding",
    image: "/assets/images/gallery/11.jpg",
    caption: "",
  },
  {
    id: 12,
    filter: "wedding",
    image: "/assets/images/gallery/12.jpg",
    caption: "",
  },
  {
    id: 13,
    filter: "workshop",
    image: "/assets/images/gallery/13.jpg",
    caption: "",
  },
  {
    id: 14,
    filter: "workshop",
    image: "/assets/images/gallery/14.jpg",
    caption: "",
  },
  {
    id: 15,
    filter: "artspace",
    image: "/assets/images/gallery/15.jpg",
    caption: "",
  },
  {
    id: 16,
    filter: "artspace",
    image: "/assets/images/gallery/16.jpg",
    caption: "",
  },
  {
    id: 17,
    filter: "workshop",
    image: "/assets/images/gallery/17.jpg",
    caption: "",
  },
  {
    id: 18,
    filter: "workshop",
    image: "/assets/images/gallery/18.jpg",
    caption: "",
  },
  {
    id: 19,
    filter: "wedding",
    image: "/assets/images/gallery/19.jpg",
    caption: "",
  },
  {
    id: 20,
    filter: "workshop",
    image: "/assets/images/gallery/20.jpg",
    caption: "",
  },
];

export default function GalleryContainer() {
  const {} = useFunction();
  // const { spaceTypeData, amenities, features } = useFunctionSpaces()

  useEffect(() => {
    startAnimation();

    const $grid = new Isotope(".wrap", {
      itemSelector: ".img-wrap",
      layoutMode: "fitRows",
    });

    // Filter functions
    const filterFns = {};

    // Bind filter button click
    $(".filters-button-group").on("click", "button", function () {
      const filterValue = $(this).attr("data-filter");
      // Use filterFn if matches value
      const newFilterValue = filterFns[filterValue] || filterValue;
      $grid.arrange({ filter: newFilterValue });
    });

    // Change is-checked class on buttons
    $(".button-group").each(function (i, buttonGroup) {
      const $buttonGroup = $(buttonGroup);
      $buttonGroup.on("click", "button", function () {
        $buttonGroup.find(".is-checked").removeClass("is-checked");
        $(this).addClass("is-checked");
      });
    });

    return () => {
      // Clean up Isotope instance and any event listeners
      $grid.isotope("destroy");
    };
  }, []);

  return (
    <div className="wrapper gallery-page">
      <NavbarSpaceComponent reqDetail={false} />
      <main>
        <div class="container1440">
          <div class="section1">
            <h1>Gallery</h1>
            <div class="button-group filters-button-group flex-row-cen-cen">
              <button class="is-checked" data-filter="*">
                All
              </button>
              {/* <button class="" data-filter=".event">
                Events
              </button> */}
              <button class="" data-filter=".wedding">
                Wedding
              </button>
              <button class="" data-filter=".workshop">
                Workshop
              </button>
              <button class="" data-filter=".artspace">
                Art Space
              </button>
            </div>
            <section class="wrap" style={{ paddingBottom: 30 }}>
              {imageGallery.length > 0 &&
                imageGallery.map((item) => (
                  <div className={`img-wrap ${item.filter}`}>
                    <div key={item.id} class="inner-img-wrap">
                      {/* <a href="#"> */}
                      <img src={item.image} alt={item.caption} />
                      {/* </a> */}
                    </div>
                    <div class="caption">{item.caption}</div>
                  </div>
                ))}
            </section>
          </div>
        </div>
      </main>
      {/* <FooterComponent /> */}
    </div>
  );
}
