import React, { useEffect } from 'react'
import gsap from 'gsap'
import Animation from 'utils/animate'
import JoinUs from 'components/detail/joinUs'
import Relative from 'components/detail/relative'
import Content from 'components/detail/content'
import PlanMap from 'components/detail/planMap'
import SlideWindows from 'components/detail/windows'
import { Container } from './component.style'

export default function Index({
  detailData,
  freeSlots,
  selectdRange,
  selectedDate,
  onSelectTimeRange,
  onSelectDate,
  onAddedExtended,
  onDeleteExtended,
  onChangePage,
  sumOrderPrice,
  sumOrderHours,
  sumOrderProcessPrice,
  findDuplicateSelected,
}) {
  useEffect(() => {
    Animation()

    gsap.to('section', {
      duration: 1,
      opacity: 1,
      delay: 0,
      ease: 'power3.out',
    })
  }, [])

  return ( 
    <section>   
      <div className="wrapper">
        {/* <NavBarHeader reqDetail={false} /> */}
        <Container>
          <Content
            sumOrderPrice={sumOrderPrice}
            sumOrderHours={sumOrderHours}
            sumOrderProcessPrice={sumOrderProcessPrice}
            onChangePage={onChangePage}
            onDeleteExtended={onDeleteExtended}
            onAddedExtended={onAddedExtended}
            onSelectDate={onSelectDate}
            selectedDate={selectedDate}
            onSelectTimeRange={onSelectTimeRange}
            selectdRange={selectdRange}
            cancellation={detailData.cancellation_policy}
            parking={detailData.parking}
            spaceType={detailData.space_type}
            freeSlots={freeSlots}
            amenities={detailData.amenities}
            findDuplicateSelected={findDuplicateSelected}
            transportation={detailData.public_transportation}
            highlight={detailData.highlight_features}
            images={detailData.images}
            area={detailData.area}
            startPrice={detailData.min_price}
            maxPeople={detailData.max_people}
            spaceLocation={detailData.location}
            spaceDetail={detailData.space_detail}
            spaceName={detailData.name}
            spaceOption={detailData.space_detail_options}
            operatingHours={detailData.opening_time}
            rules={detailData.space_rules}
            availableActivity={detailData.event}
          />
          <PlanMap detailData={detailData} />
          <Relative
            findDuplicateSelected={findDuplicateSelected}
            sumOrderPrice={sumOrderPrice}
            sumOrderHours={sumOrderHours}
            sumOrderProcessPrice={sumOrderProcessPrice}
            onChangePage={onChangePage}
            onDeleteExtended={onDeleteExtended}
            onAddedExtended={onAddedExtended}
            onSelectDate={onSelectDate}
            selectedDate={selectedDate}
            onSelectTimeRange={onSelectTimeRange}
            selectdRange={selectdRange}
            freeSlots={freeSlots}
            spaceName={detailData.name}
            space={detailData}
          />
          <SlideWindows neighborhood={detailData.neighborhood} />
          <JoinUs />
        </Container>
      </div>
    </section>
  )
}
