import React from 'react'

export default function Index() {
  return (
    <section className="bg-yellow">
      <section className="joining joining_main bg-yellow py-12 md:py-10">
      <div className="grid text-center items-center">
        <div>
          <p>
            หากคุณมีพื้นที่อยากเข้าร่วมกับ GU Space
            <br />
            สามารถส่งข้อมูลหาเราได้ที่นี่
          </p>
        </div>
        <div>
          <img src="../../assets/images/join.svg" alt="" className="mx-auto" />
          <h2 className="uppercase font-bold font-noto text-3xl mt-3">
            Joining our
            <br />
            neighborhood
          </h2>
        </div>
        <div>
          <a target="_blank"
            href="https://docs.google.com/forms/d/e/1FAIpQLSdc44zPyubKfep2COUNB1vGBL5wH_NdpaQmzpInAq1C8CrtbA/viewform?usp=pp_url"
            className="btn bg-black inline-block text-white rounded-full py-2.5 p-8 font-bold font-ibm text-base md:text-lg hover:underline"
          >
            กรอกแบบฟอร์ม
          </a>
        </div>
      </div>
    </section>
    </section>
  )
}
