import React, { useState, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import { Button, Popover } from "antd";
import defaultImage from "../../../assets/images/default-image.jpg";
import "./style.css";
const styles = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#F7F6F4",
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#A1A1A0",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#bdbdbd",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#EAE7DD",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "poi.business",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#D4CEB9",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#F7F6F4",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#C4BFAE",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#C4BFAE",
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#D4CEB9",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#D4CEB9",
      },
    ],
  },
];

export default function MapComponent({
  spaces,
  location,
  center = { lat: 13.736717, lng: 100.523186 },
  markRender = false,
}) {
  // const [locations, setLocations] = useState(spaces)
  const [chooseLocation, setChooseLocation] = useState({});
  const defaultCenter = { lat: 13.736717, lng: 100.523186 };
  const handleMapLoaded = (map) => {};

  useEffect(() => {
    if (location) {
      setChooseLocation(location);
    }
  }, [location, chooseLocation]);

  function Marker(space, index) {
    console.log("space", space);

    if (space.id === chooseLocation.id) {
      return (
        <div key={index} lat={space.lat} lng={space.long} text={space.space_detail_options}>
          {markRender && (
            <Popover
              overlayClassName="marker-popover"
              color="#fbff25"
              placement="top"
              open={chooseLocation.id === space.id && markRender}
              content={() => {
                return (
                  <div style={{ width: "100px" }}>
                    <img
                      style={{ height: "77.3px", width: "116px", aspectRatio: "3 / 2" }}
                      src={space.images_display ? space.images_display[0] : defaultImage}
                      alt=""
                    />
                    <h2 className="mt-2 text-sm font-semibold font">{space.space_detail_options} </h2>
                  </div>
                );
              }}
            >
              <a href="#!" className="marker" aria-label={`marker-${space.id}`}>
                <img src="/assets/images/pin_map.svg" alt="" />
              </a>
            </Popover>
          )}
        </div>
      );
    } else {
      return (
        <div key={index} lat={space.lat} lng={space.long} text={space.space_detail_options}>
          <Popover
            // onOpenChange={() => {
            //   setChooseLocation(space)
            // }}
            color="#fbff25"
            placement="top"
            content={() => {
              return (
                <div style={{ width: "100px" }}>
                  <img
                    style={{ height: "77.3px", width: "116px", aspectRatio: "3 / 2" }}
                    src={space.images_display ? space.images_display[0] : defaultImage}
                    alt=""
                  />
                  <h2 className="mt-2 text-sm font-semibold font">{space.space_detail_options}</h2>
                </div>
              );
            }}
          >
            <a className="marker">
              <img src="/assets/images/pin_map.svg" alt="" />
            </a>
          </Popover>
        </div>
      );
    }
  }

  return (
    <div className="col sticky   ">
      <div className="map-space-all ">
        <div id="map" style={{ background: "#e5e4dc" }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_KEY, styles: styles }}
            defaultCenter={defaultCenter}
            defaultZoom={13}
            center={center?.lat ? { lat: Number(center.lat), lng: Number(center.lng) } : defaultCenter}
            options={{
              styles: styles,
            }}
          >
            {spaces &&
              spaces.map((space, index) => {
                return Marker(space, index);
              })}
          </GoogleMapReact>
        </div>
        <div className="btn-yellow btn_closemap">Close</div>
      </div>
    </div>
  );
}
