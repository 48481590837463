import React, { useState, useEffect, useRef } from 'react'

function CustomSelect({ options, placeholder = '', onSelect, value = '', all = false, defaultValue = null, defaultSelectFunction }) {
  const [selectedItem, setSelectedItem] = useState()
  const [isExpanded, setIsExpanded] = useState(false)
  const [clickedInside, setClickedInside] = useState(false)
  const inSideRef = useRef()

  const handleItemClick = (item) => {
    setSelectedItem(item)
    setIsExpanded(false)

    if (!onSelect) return
    if (item.value !== '') {
      onSelect(item)
    } else {
      onSelect({ name: '' })
    }
  }

  const toggleSelect = () => {
    setIsExpanded(!isExpanded)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inSideRef.current && !inSideRef.current.contains(event.target)) {
        setClickedInside(false)
        setIsExpanded(false)
      }
    }
    if (value) {
      handleItemClick(value)
    }

    if (defaultValue && defaultSelectFunction) {
      defaultSelectFunction(defaultValue)
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [inSideRef, defaultValue])

  return (
    <div className="" ref={inSideRef} onClick={() => setClickedInside(true)}>
      <div className="select-selected" onClick={toggleSelect}>
        {defaultValue ? defaultValue : selectedItem ? selectedItem.name : placeholder}
      </div>
      {clickedInside && isExpanded && (
        <div className="select-items">
          {all ? (
            <div
              className={'same-as-selected'}
              onClick={() =>
                handleItemClick({
                  name: 'All',
                  value: '',
                })
              }
            >
              All
            </div>
          ) : (
            ''
          )}
          {options &&
            options.map(
              (option, index) =>
                option && (
                  <div
                    key={index}
                    className={defaultValue ? defaultValue === option.value && 'same-as-selected' : option === selectedItem ? 'same-as-selected' : ''}
                    onClick={() => handleItemClick(option)}
                  >
                    {option && option.name}
                  </div>
                )
            )}
        </div>
      )}
    </div>
  )
}

export default CustomSelect
