import React, { useEffect } from "react";
import { Image } from "antd";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, EffectCards } from "swiper";
import { useNavigate } from "react-router-dom";
import { Fancybox } from "@fancyapps/ui/dist/fancybox/fancybox.esm.js";

import defaultImage from "../../../assets/images/default-image.jpg";
import addDayIcon from "assets/images/extened_day.png";
import DateRangPicker from "components/global/dateRangPicker";
import DatePicker from "../../global/datePicker";
import gsap from "gsap";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-cards";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import "./style.css";

export default function Index({
  // Valiable
  startPrice,
  maxPeople,
  spaceLocation,
  spaceDetail,
  spaceName,
  operatingHours = [],
  images = [],
  rules,
  parking,
  transportation,
  cancellation,
  spaceOption,
  highlight,
  amenities,
  availableActivity,
  spaceType,
  selectdRange = null,
  freeSlots = [],
  sumOrderPrice,
  area,
  sumOrderHours,
  sumOrderProcessPrice,
  // Function
  onSelectTimeRange,
  onSelectDate,
  onAddedExtended,
  onDeleteExtended,
  onChangePage,
  findDuplicateSelected,
}) {
  const navigate = useNavigate();
  const areaMaping = {
    "sqm.": "ตรม.",
  };

  useEffect(() => {
    const timeLine = gsap.timeline({ delay: 1.2 });

    Fancybox.bind('[data-fancybox="gallery"]', {
      // Your custom options
    });

    timeLine
      .from(".single_content .txt-name", {
        duration: 0.8,
        opacity: 0,
        y: 100,
        ease: "power4.out",
      })
      .from(".single_content .space-name", {
        duration: 0.8,
        opacity: 0,
        y: 100,
        ease: "power4.out",
      })
      .from(
        ".single_content .txt-location",
        {
          duration: 0.8,
          opacity: 0,
          y: 100,
          ease: "power4.out",
        },
        "<0.1"
      )
      .from(
        ".single_content .txt-space-type",
        {
          duration: 0.8,
          opacity: 0,
          y: 100,
          ease: "power4.out",
        },
        "<0.1"
      )
      .from(
        ".single_content .sub_info",
        {
          duration: 0.8,
          opacity: 0,
          y: 100,
          ease: "power4.out",
        },
        "<0.1"
      )
      .from(
        ".single_content .txt-desc",
        {
          duration: 0.8,
          opacity: 0,
          y: 100,
          ease: "power4.out",
        },
        "<0.1"
      )
      .from(
        ".single_content .filter_date",
        {
          duration: 0.8,
          opacity: 0,
          y: 100,
          ease: "power4.out",
        },
        "<0.1"
      )
      .from(
        ".single_content .group",
        {
          duration: 0.8,
          opacity: 0,
          // y: 100,
          ease: "power4.out",
        },
        "<0.1"
      );
  }, []);

  return (
    <div className="container_gu">
      <section className="single_space">
        <div className="col">
          <div className="single_content">
            <button className="btn_back" onClick={() => navigate(-1)} aria-label="back-to-spaces">
              <img src="../../assets/images/i_back.svg" alt="" />
            </button>
            <div className="group mt-8">
              <div className="txt-name" style={{ paddingTop: 0 }}>
                <h2 style={{ lineHeight: "80px", fontSize: 60 }}>{spaceOption}</h2>
              </div>
              {/*<div className="space-name">*/}
              {/*  <h2 style={{ fontSize: 30, margin: '15px 0 10px 0', textTransform: 'capitalize' }}>{spaceName}</h2>*/}
              {/*</div>*/}
              <div className="txt-location" style={{ textTransform: "capitalize", fontSize: 20 }}>
                {spaceLocation}
              </div>
              <div style={{ display: "flex", margin: "10px 0px" }}>
                <div className="txt-space-type" style={{ textTransform: "capitalize" }}>
                  {spaceType}
                </div>
              </div>

              <div className="sub_info my-6">
                <div className="item">
                  <img src="../../assets/images/i_plan.svg" alt="" />
                  <span>
                    {area && area.size} {area && area.unit}
                  </span>
                </div>
                <div className="item">
                  <img src="../../assets/images/i_people.svg" alt="" />
                  <span>Max {maxPeople} people</span>
                </div>
                <div className="item">
                  <img src="../../assets/images/i_baht.svg" alt="" />
                  <span>Starting from {new Intl.NumberFormat().format(startPrice)} THB/hr.</span>
                </div>
              </div>

              <div className="txt-desc">
                <p style={{ whiteSpace: "pre-wrap" }}>{spaceDetail || "-"}</p>
              </div>
            </div>
            <div className="group">
              <div className="thumbnail">
                <div className="inner">
                  <Swiper
                    className="swiper-wrapper-card slide-single-space-thumb"
                    effect={"cards"}
                    grabCursor={true}
                    modules={[Navigation, EffectCards]}
                    navigation={true}
                    pagination={{ clickable: true }}
                  >
                    {images &&
                      images.length > 0 &&
                      images.map((img) => {
                        return (
                          <SwiperSlide>
                            <a href={img ? img : defaultImage} data-fancybox="gallery" data-caption="">
                              <Image
                                src={img ? img : defaultImage}
                                alt="images"
                                width="100%"
                                height="auto"
                                preview={false}
                              />
                            </a>
                          </SwiperSlide>
                        );
                      })}
                    {images.length === 0 && (
                      <SwiperSlide>
                        <Image
                          src="../../assets/images/default.png"
                          alt="images"
                          width="550px"
                          height="auto"
                          preview={false}
                        />
                      </SwiperSlide>
                    )}
                    {/* images */}
                  </Swiper>
                  {images && images.length > 0 && (
                    <a href="#gallery-1" className="btn_seeall_thumb">
                      <img src="../../assets/images/i_thumb_seeall.svg" alt="" />
                      <p>
                        See all
                        <br />
                        <span className="font-bold">{images.length} Photos</span>
                      </p>
                    </a>
                  )}
                </div>
              </div>
            </div>
            <div className="group">
              <div className="filter_date py-gu-25">
                {freeSlots.length > 0 &&
                  freeSlots.map((freeSlot) => {
                    return (
                      <div style={{ marginBottom: 10 }}>
                        <div className="gu_date">
                          <DatePicker
                            onSelect={(e) => onSelectDate(e, freeSlot.index)}
                            placeholder="Select date"
                            defaultValue={freeSlot.selected_date}
                            value={freeSlot.selected_date}
                          />
                        </div>
                        <div className="gu_time">
                          <DateRangPicker
                            onSelect={(e) => onSelectTimeRange(e, freeSlot.index)}
                            options={
                              freeSlot && freeSlot.free_slots
                                ? freeSlot.free_slots.map((slot) => {
                                    let isDuplicate = findDuplicateSelected(freeSlots, freeSlot, slot);
                                    return {
                                      value: {
                                        start: slot.start,
                                        end: slot.end,
                                        price_process: parseInt(slot.price_process),
                                      },
                                      name: `${slot.part} (${slot.start} - ${slot.end})`,
                                      disabled: isDuplicate ? isDuplicate : !slot.status,
                                    };
                                  })
                                : [
                                    {
                                      value: "none",
                                      name: `Something went wrong.`,
                                      disabled: true,
                                    },
                                  ]
                            }
                            placeholder="Select Hour"
                            value={freeSlot.selected_range}
                          />
                        </div>
                      </div>
                    );
                  })}
                <div className="added-day-container">
                  {freeSlots.length < 7 ? (
                    <div className="added-day" onClick={onAddedExtended}>
                      <img src="../../assets/images/icon_extendaday.svg" alt="added_day" /> <span>Extend a day</span>
                    </div>
                  ) : (
                    <div></div>
                  )}
                  {freeSlots.length > 1 && (
                    <div className="delete-day" onClick={onDeleteExtended}>
                      Remove
                    </div>
                  )}
                </div>
                {selectdRange ? (
                  <div className="gu_result">
                    <h2>Price</h2>
                    <div className="details">
                      <div className="list">
                        <p>{sumOrderHours} hours</p>
                        <p>{new Intl.NumberFormat().format(sumOrderPrice)} บาท</p>
                      </div>
                      <div className="list">
                        <p>Discount</p>
                        <p>{new Intl.NumberFormat().format(sumOrderProcessPrice)} บาท</p>
                      </div>
                    </div>
                    <div className="total">
                      <p>Total</p>
                      <p>{new Intl.NumberFormat().format(sumOrderPrice + sumOrderProcessPrice)} บาท</p>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div
                  className="btn_request btn-yellow w-full text-center items-center justify-center mt-4"
                  style={{ cursor: "pointer" }}
                  onClick={() => onChangePage(1)}
                >
                  Request to book
                </div>
              </div>
            </div>

            <div className="group mt-8">
              <div className="facilities" style={{ padding: "1rem 0 3rem 0" }}>
                <h2 className="txthead_space icon_head">Avaliable Activity</h2>
                <div className="inner">
                  <div>
                    {availableActivity && availableActivity.length > 0
                      ? availableActivity.map((item) => {
                          return (
                            item.name && (
                              <div
                                className={`${!item.icon && "icon_head"}`}
                                key={item.name}
                                style={{ display: "flex", alignItems: "center", gap: 8, marginTop: 15 }}
                              >
                                {item.icon && (
                                  <img
                                    src={item.icon || "../../assets/images/i_facilities_table.png"}
                                    alt="icon"
                                    style={{ width: 35 }}
                                  />
                                )}
                                <div style={{ textTransform: "capitalize" }}>{item.name || "-"}</div>
                              </div>
                            )
                          );
                        })
                      : "-"}
                  </div>
                </div>
              </div>

              <div className="facilities py-12">
                <h2 className="txthead_space icon_head">Included in your booking</h2>
                <div className="inner">
                  <h2>Amenities</h2>
                  <div className="amenities">
                    {amenities && amenities.length > 0
                      ? amenities.map((item) => {
                          return (
                            <div key={item.name} style={{ display: "flex", alignItems: "center", gap: 8 }}>
                              <img
                                src={item.icon || "../../assets/images/i_facilities_table.png"}
                                alt="icon"
                                style={{ width: 35 }}
                              />
                              <div>{item.name || "-"}</div>
                            </div>
                          );
                        })
                      : "-"}
                  </div>
                </div>
                <div className="inner">
                  <h2>Features</h2>
                  <div className="amenities">
                    {highlight && highlight.length > 0
                      ? highlight.map((item) => {
                          return (
                            <div key={item.name} style={{ display: "flex", alignItems: "center", gap: 8 }}>
                              <img
                                src={item.icon || "../../assets/images/i_facilities_kitchen.png"}
                                alt="icon"
                                style={{ width: 35 }}
                              />
                              <div>{item.name || "-"}</div>
                            </div>
                          );
                        })
                      : "-"}
                  </div>
                </div>
              </div>

              <div className="facilities py-12">
                <h2 className="txthead_space icon_head">Transportation</h2>
                <div className="inner">
                  <h2>Parking Space</h2>
                  <div className="flex items-start">
                    <img src="../../assets/images/i_transportation_car.svg" alt="" className="img_transportation" />
                    <p className="pl-5">{parking ? parking : "-"}</p>
                  </div>
                </div>
                <div className="inner">
                  <h2>Public transportation</h2>
                  <div className="flex items-start">
                    <img src="../../assets/images/i_transportation_bus.svg" alt="" className="img_transportation" />
                    <p className="pl-5">{transportation ? transportation : "-"}</p>
                  </div>
                </div>
              </div>

              <div className="facilities py-12">
                <h2 className="txthead_space icon_head">Something to keep in mind</h2>
                <div className="inner">
                  <h2 style={{ margin: "16px 0 8px 0" }}>General Rules</h2>
                  <ul className="rules">{rules ? rules : "-"}</ul>
                </div>
              </div>

              <div className="facilities py-12">
                <h2 className="txthead_space icon_head">Operating Hours</h2>
                <div className="inner">
                  <div className="operation_hours mt-6">
                    {operatingHours.map((operating) => {
                      return (
                        <div className="flex pb-2">
                          <p className="w-30" style={{ textTransform: "capitalize" }}>
                            {operating.day}
                          </p>
                          <p className="w-70">
                            {operating.start} - {operating.end} hrs.
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="facilities py-12">
                <h2 className="txthead_space icon_head">Cancellation Policy</h2>
                <div className="inner">
                  <p className="mt-6">{cancellation ? cancellation : "-"}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col"></div>
      </section>
    </div>
  );
}
