import React, { useState, useEffect } from 'react'
import CommonServives from 'utils/services/api/common'
import { master_data } from 'utils/services/api/schemas'

export default function ListViewFunction() {
  const [masterData, setMasterData] = useState({})
  const [spaceTypeData, setSpaceType] = useState([])
  const [amenities, setAmenities] = useState([])
  const [features, setFeatures] = useState([])
  const { getList } = CommonServives()

  useEffect(() => {
    GetMasterData();
  }, [])

  function GetMasterData() {
    getList(master_data)
      .then((response) => {
        setMasterData(response.data)
        let spaceType = response.data.space_type ? response.data.space_type : []
        let amenities = response.data.amenities ? response.data.amenities : []
        let features = response.data.highlight_features ? response.data.highlight_features : []
        setSpaceType(spaceType)
        setAmenities(amenities)
        setFeatures(features)
      })
      .catch((err) => {
        alert(err)
      })
  }
  return { masterData, spaceTypeData, amenities, features, GetMasterData }
}
