import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: var(--fontNoto);
  padding-top: 17vh;

  @media (max-width: 1023px) {
    padding-top: 5vh;
  }

  @media (max-width: 767px) {
    padding-top: 5vh;
  }
`

export const Image = styled.img`
  width: 100px;
  height: 100px;
`

export const Title = styled.p`
  color: black;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
`
