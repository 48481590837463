import React from "react";
export default function FooterColComponent() {
  return (
    <footer class="footer_main">
      <script src="https://www.line-website.com/social-plugins/js/thirdparty/loader.min.js" async="async" defer="defer"></script>
      <div className="inner">
        <div className="top">
          <div className="logo_footer">
            <img src="assets/images/logo-gu-space.svg" alt="" />
          </div>
          <div className="social">
            <a target="_blank" href="https://www.facebook.com/guspacebkk">
              <img src="/assets/images/social/fb_bw.svg" alt="" aria-label="facebook" />
            </a>
            <a target="_blank" href="https://www.tiktok.com/@guspacebkk">
              <img src="/assets/images/social/tiktok.svg" alt="" aria-label="tiktok" />
            </a>
            <a href="https://www.instagram.com/guspace_bkk" target="_blank">
              <img src="/assets/images/social/ig_bw.svg" alt="" aria-label="ig" />
            </a>
            <a href="https://lin.ee/8pqDcBM" target="_blank">
              <img src="/assets/images/social/line_bw.svg" alt="" aria-label="line" />
            </a>
          </div>
        </div>
        <div className="copyright">
          <p>&copy; 2023 GU SPACE. Registered in Thailand. Company Registration Number 13324675. VAT Number 3795673330. Address: 18 Golden Road, Bangkok Thailand, 10900 Privacy Policy</p>
        </div>
      </div>
    </footer>
  );
}
