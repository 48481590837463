import { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ConfigProvider } from "antd";
import { Layout } from "antd";
import { Loading, LineLogin } from "utils/services/store";

import Home from "./containers/home";
import Detail from "./containers/space";
import Spaces from "./containers/spaces";
import About from "./containers/about";
import Gallery from "./containers/gallery";
import ErrorPage from "./containers/error";
import Line from "./containers/space/booking/line";
import LoadginScene from "components/global/loading";
// import ReactGA from 'react-ga'
import ReactGA from "react-ga4";
import Contact from "./containers/contact";
import "antd/dist/reset.css";
import "./assets/css/variables.css";
import "./assets/css/flowbite.min.css";
import "./assets/css/fancybox.css";
import "./assets/css/swiper-bundle.min.css";
import "./assets/css/main.css";
import "./assets/css/responsive.css";
import "./assets/css/header_footer.css";
import "./assets/fonts/fonts.css";
import "./assets/css/timepicker.min.css";
import "./assets/css/swiper-bundle.min.css";
import "./assets/css/main_homepage.css";
import "flowbite-datepicker";
import "./App.css";

const { Content } = Layout;
function App() {
  const [isLoading, setIsLoading] = useState(false);
  const [lineLoginData, setLineLoginData] = useState(null);
  const TRACKING_ID = process.env.REACT_APP_TRACKING_ID_GA; // OUR_TRACKING_ID

  // useEffect(() => {
  //   ReactGA.initialize(TRACKING_ID);
  // }, [TRACKING_ID]);

  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/", title: "Gu Space" });
    ReactGA.send({ hitType: "pageview", page: "/spaces", title: "Gu Space - Spaces" });
    ReactGA.send({ hitType: "pageview", page: "/about", title: "Gu Space - About" });
    ReactGA.send({ hitType: "pageview", page: "/gallery", title: "Gu Space - Gallery" });
    ReactGA.send({ hitType: "pageview", page: "/contact", title: "Gu Space - Contact" });
  }, [TRACKING_ID]);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#00b96b",
        },
      }}
    >
      <LineLogin.Provider value={{ lineLoginData, setLineLoginData }}>
        <Loading.Provider value={{ isLoading, setIsLoading }}>
          <LoadginScene isLoading={isLoading} />
          <BrowserRouter>
            <Layout>
              <Content>
                <Routes>
                  {/*exact คือ path ต้อง match เท่านั้น ถ้า home ต้อง home เท่านั้น ถ้าไม่มี exact เข้า path homes จะเข้ามาที่ component(element) ดังกล่าวได้*/}
                  <Route exact path="/" element={<Home />} />
                  <Route exact path="/spaces" element={<Spaces />} />
                  <Route exact path="/about" element={<About />} />
                  <Route exact path="/gallery" element={<Gallery />} />
                  <Route exact path="/contact" element={<Contact />} />
                  <Route exact path="/spaces/:id" element={<Detail />} />
                  <Route exact path="/line" element={<Line />} />
                  <Route path="*" element={<ErrorPage />} />
                </Routes>
              </Content>
            </Layout>
          </BrowserRouter>
        </Loading.Provider>
      </LineLogin.Provider>
    </ConfigProvider>
  );
}

export default App;
