import React from "react";
import CustomSelect from "../../global/customSelect";
import DatePicker from "../../global/datePicker";
import useFunction from "./function";
export default function HeroBannerComponent({ masterData }) {
  const { searchParams, onSelectLocation, onSelectEvent, onSelectPeople, onSelectDate, generateSearchParams } = useFunction();

  return (
    <section className="herobanner">
      <div className="inner">
        <div className="txt text-center">
          <h1>
            OLD TOWN
            <br />
            SPACE
            <br />
            FOR{" "}
            <span className="line_any">
              ANY
              <svg width="235" height="30" viewBox="0 0 235 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M3.51796 17.9186C0.868557 17.5889 9.22426 13.1249 9.64199 12.8754C15.8284 9.18048 23.132 4.37302 30.3524 3.06622C34.6187 2.29405 31.5772 18.4223 31.5772 22.4076C31.5772 32.0117 38.1894 24.0641 42.8231 20.9112C44.1978 19.9759 61.4768 8.0018 61.6406 10.9358C61.8682 15.0146 61.4907 20.5181 65.148 23.3497C70.0511 27.1458 78.9535 18.9602 82.1839 16.3669C83.9061 14.9843 96.7146 2.81906 96.7146 7.94312C96.7146 12.2828 97.7743 19.4753 103.729 17.6969C112.698 15.0187 118.824 4.71231 128.226 3.0108C130.115 2.66884 131.121 10.5498 131.789 12.3767C133.589 17.3056 148.078 11.8437 150.606 10.9358C154.806 9.42707 164.882 1.33407 166.64 7.94312C170.225 21.426 182.189 14.9464 191.86 13.9838C193.824 13.7883 194.757 14.1692 196.369 14.7043C201.659 16.4594 211.193 9.187 215.744 7.38892C220.51 5.50561 232 -0.869643 232 6.94557"
                  stroke="#FBFF25"
                  strokeWidth="5"
                  strokeLinecap="round"
                />
              </svg>
            </span>
            <br />
            possibility
          </h1>
          <h2 style={{ fontWeight: "normal" }}>Book the space, create any possibility</h2>
        </div>
        <div className="group_search" style={{ opacity: 1 }}>
          <div className="custom-select dropdown w-50 input-location" style={{ backgroundColor: "white" }}>
            <CustomSelect
              all={true}
              options={
                masterData &&
                masterData.location &&
                masterData.location.map(
                  (item) =>
                    item.status === "ACTIVE" && {
                      name: item.name,
                      value: item.name,
                      disabled: item.status,
                    }
                )
              }
              placeholder="Select Location"
              onSelect={onSelectLocation}
            />
          </div>
          <div className="custom-select dropdown w-50 input-activity" style={{ backgroundColor: "white", textTransform: "capitalize" }}>
            <CustomSelect
              all={true}
              options={
                masterData &&
                masterData.event &&
                masterData.event.map(
                  (item) =>
                    item.status === "ACTIVE" && {
                      name: item.name,
                      value: item.name,
                      disabled: item.status,
                    }
                )
              }
              placeholder="Select Activity"
              onSelect={onSelectEvent}
            />
          </div>
          <div className="custom-select dropdown w-50 input-people" style={{ backgroundColor: "white" }}>
            <CustomSelect
              all={true}
              options={
                masterData &&
                masterData.people &&
                masterData.people.map(
                  (item) =>
                    item.status === "ACTIVE" && {
                      name: `${item.number_of_people} People`,
                      value: item.number_of_people,
                      disabled: item.status,
                    }
                )
              }
              placeholder="Number of People"
              onSelect={onSelectPeople}
            />
          </div>
          <div className="dropdown w-50 input-datepicker">
            {/* <input type="text" className="datepicker-input" datepicker="" datepicker-autohide="" placeholder="Select Date" /> */}
            <DatePicker placeholder={"Select Date"} onSelect={onSelectDate} />
          </div>
          <a
            href={`/spaces${searchParams ? `?${generateSearchParams()}` : ""}`}
            // onClick={() => generateSearchParams()}
            type="button"
            className="btn-yellow w-full text-center justify-center mt-6"
          >
            SEARCH
          </a>
        </div>
      </div>
      <div className="img">
        <div className="thumb_set">
          <div className="pos thumb thumb01">
            <img src="/assets/images/picture_herobanner/set01_thumb01.jpg" alt="" />
            <img src="/assets/images/picture_herobanner/set02_thumb01.jpg" alt="" />
          </div>
          <div className="pos thumb thumb02">
            <img src="/assets/images/picture_herobanner/set01_thumb02.jpg" alt="" />
            <img src="/assets/images/picture_herobanner/set02_thumb02.jpg" alt="" />
          </div>
          <div className="pos thumb thumb03">
            <img src="/assets/images/picture_herobanner/set01_thumb03.jpg" alt="" />
            <img src="/assets/images/picture_herobanner/set02_thumb03.jpg" alt="" />
          </div>
          <div className="pos thumb thumb04">
            <img src="/assets/images/picture_herobanner/set01_thumb04.jpg" alt="" />
            <img src="/assets/images/picture_herobanner/set02_thumb04.jpg" alt="" />
          </div>
          <div className="pos thumb thumb05">
            <img src="/assets/images/picture_herobanner/set01_thumb05.jpg" alt="" />
            <img src="/assets/images/picture_herobanner/set02_thumb05.jpg" alt="" />
          </div>
          <div className="pos thumb thumb06">
            <img src="/assets/images/picture_herobanner/set01_thumb06.jpg" alt="" />
            <img src="/assets/images/picture_herobanner/set02_thumb06.jpg" alt="" />
          </div>
          <div className="pos thumb thumb07">
            <img src="/assets/images/picture_herobanner/set01_thumb07.jpg" alt="" />
            <img src="/assets/images/picture_herobanner/set02_thumb07.jpg" alt="" />
          </div>
        </div>
      </div>
    </section>
  );
}
