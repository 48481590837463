import $ from 'jquery'
import 'jquery.cycle2'

const startMenu = () => {
  $(function () {
    //Random images
    let imgExpand = $('.expand .img img')
    for (let i = 1; i <= imgExpand.length; i++) {
      $(imgExpand).each(function () {
        $(this).attr('id', 'random_' + i)
        i++
      })
    }

    $('header .btn_hamburger').click(function () {
      $('.expand').addClass('active')
      $('html').css('overflow', 'hidden')
    })

    $('.btn_close_menu').click(function () {
      let imgRandom = Math.floor(Math.random() * imgExpand.length) + 1
      $('.expand .img img').removeClass('active')
      $('.expand .img img[id="random_' + imgRandom + '"]').addClass('active')
      $('.expand').removeClass('active')
      $('html').css('overflow', 'visible')
    })
  })
}

export default startMenu
