import React, { useEffect, useState } from 'react'
import GoogleMapReact from 'google-map-react'
import PinMap from 'assets/images/pin_map.svg'
import defaultImage from '../../../assets/images/default-image.jpg'
import { Popover } from 'antd'

export default function Index({ detailData }) {
  const styles = [
    {
      elementType: 'geometry',
      stylers: [
        {
          color: '#F7F6F4',
        },
      ],
    },
    {
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#A1A1A0',
        },
      ],
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: '#f5f5f5',
        },
      ],
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#bdbdbd',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          color: '#EAE7DD',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'labels.text',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575',
        },
      ],
    },
    {
      featureType: 'poi.business',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [
        {
          color: '#D4CEB9',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [
        {
          color: '#ffffff',
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [
        {
          color: '#F7F6F4',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#C4BFAE',
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#C4BFAE',
        },
      ],
    },
    {
      featureType: 'transit.line',
      elementType: 'geometry',
      stylers: [
        {
          color: '#ffffff',
        },
      ],
    },
    {
      featureType: 'transit.station',
      elementType: 'geometry',
      stylers: [
        {
          color: '#ffffff',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: '#D4CEB9',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#D4CEB9',
        },
      ],
    },
  ]

  const [center, setCenter] = useState({ lat: 13.745683, lng: 100.603647 })
  const [data, setData] = React.useState({})
  useEffect(() => {
    setData(detailData)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      if (detailData && detailData.lat && detailData.long) {
        setCenter({ lat: parseFloat(detailData.lat), lng: parseFloat(detailData.long) })
      }
    }, 1000)
  }, [detailData])

  const Marker = () => (
    <div>
      <Popover
        style={{ zIndex: 3 }}
        autoAdjustOverflow
        autoHideDuration={3000}
        color="#fbff25"
        placement="top"
        open={true}
        content={() => {
          return (
            <div style={{ maxWidth: 120, width: '100%', display: 'flex', alignItems: 'center', justifyItems: 'center', flexDirection: 'column' }}>
              <img style={{ width: '100%' }} src={detailData.images_display ? detailData.images_display[0] : defaultImage} alt="" />
              {/* <h2 className="mt-2 text-sm font-semibold font">{detailData.name}</h2> */}
              <h2 className="mt-2 text-sm font-semibold font">{detailData.space_detail_options} </h2>
            </div>
          )
        }}
      >
        <img src={PinMap} alt="icon" style={{ opacity: 1, display: 'block', width: 40 }} />
      </Popover>
    </div>
  )

  return (
    <div className="container_gu">
      <section className="plan-and-map">
        {detailData.flow_plan && detailData.flow_plan ? (
          <div className="col">
            <h2 className="txthead_space icon_head">Floor plan</h2>
            <div className="img mt-6" style={{ position: 'relative !important', top: 0 }}>
              <img src="../../assets/images/plan.svg" alt="" />
            </div>
          </div>
        ) : (
          ''
        )}

        <div className="col" style={{ width: '100%' }}>
          <h2 className="txthead_space icon_head">Location {data.location ? ` : ${data.location}` : ''}</h2>
          <div className="map w-full mt-6" style={{ position: 'relative !important', top: 0, width: `${data.flow_plan ? `100%` : `100%`}` }}>
            {detailData && (
              <GoogleMapReact
                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_KEY }}
                defaultCenter={center}
                center={center}
                defaultZoom={18}
                options={{
                  styles: styles,
                }}
              >
                <Marker lat={detailData.lat} lng={detailData.long} />
              </GoogleMapReact>
            )}
          </div>
        </div>
      </section>
    </div>
  )
}
