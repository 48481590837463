import React, { useRef, useEffect } from 'react'
import { Datepicker } from 'flowbite-datepicker'

const MyDatePicker = ({ placeholder, onSelect, defaultValue = null, value = null, style }) => {
  const datepickerRef = useRef()
  useEffect(() => {
    const options = {
      dateFormat: 'YYYY-MM-DD',
      minDate: new Date(new Date().setDate(new Date().getDate() + 3)),
      // onSelect: handleDateChange,
    }
    const datepicker = new Datepicker(datepickerRef.current, options)
    const handleOutsideClick = (event) => {
      const isDateCell = event.target.classList.contains('fb-datepicker-date')
      const isPrevButton = event.target.classList.contains('prev-btn')
      const isClickArrow = event.target.classList.contains('w-4')
      const isNextButton = event.target.classList.contains('next-btn')
      if (datepickerRef.current && !datepickerRef.current.contains(event.target) && !isDateCell && !isPrevButton && !isNextButton && !isClickArrow) {
        datepicker.hide()
      }
    }

    document.addEventListener('click', handleOutsideClick)

    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [])

  const handleDateChange = (date) => {
    if (!onSelect) return
    onSelect(date.target.value)
  }

  return <input style={style} value={value ? value : null} defaultValue={defaultValue ? defaultValue : null} ref={datepickerRef} placeholder={placeholder} datepicker-autohide onSelect={handleDateChange} />
}

export default MyDatePicker
