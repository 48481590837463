import React, { useEffect } from 'react'
import gsap from 'gsap'

import './style.css'

export default function ThankYouContainer({ resultParams }) {
  useEffect(() => {
    gsap.to('section', {
      duration: 2,
      opacity: 1,
      delay: 1,
      ease: 'power3.out',
    })

    let thankyouAnimate = gsap.timeline({ delay: 1.2 })
    thankyouAnimate
      .from('.frame', 1, { y: 100, ease: 'power2.out' })
      .from('.frame .pencil', 1.5, { right: '75%', ease: 'power2.out' }, '<0.75')
      .from('.frame .dear h2', 1.5, { clipPath: 'inset(0 100% 0 0)', ease: 'power2.out' }, '<')
  }, [])

  return (
    <section>
      <div className="wrapper">
        <main style={{ minHeight: 'auto' }}>
          <div className="bg_brick02"></div>
          <div className="frame" style={{ margin: '10px auto 30px auto' }}>
            <div className="dear">
              <div className="dear_inner">
                <h2>Dear {resultParams.full_name},</h2>
                <img src="../../../../assets/images/thankyou_pencil.svg" alt="" className="pencil" />
              </div>
            </div>
            <div className="details">
              <p>
                I wanted to take a moment to express my sincere gratitude for choosing to book with us. We appreciate your trust in our services, and
                we look forward to serving you soon.
              </p>
              <p>
                Your booking confirmation has been received, and we are already making arrangements to ensure that your experience with us exceeds
                your expectations. We value your business and will do everything in our power to provide you with a memorable and satisfying
                experience.
              </p>
              <h4>Next step</h4>
              <p>
                <span className="highlight">
                  Our team will contact you within 12 hours if your booking is confirmed by the owner, if you haven't heard form us please do not
                  hesitate to contact us.
                </span>
                We are always available to assist you and ensure that your stay with us is comfortable and enjoyable.
              </p>
              <p>Once again, thank you for choosing us, and we look forward to seeing you soon.</p>
            </div>
            <div className="best_regards">
              <img src="../../../../assets/images/thankyou_best_regards.svg" alt="" />
            </div>
            <div className="back-to-home" onClick={() => (window.location.href = '/')}>
              Back To Home
            </div>
          </div>
        </main>
        <footer></footer>
      </div>
    </section>
  )
}
