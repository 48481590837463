import React, { useRef, useEffect } from 'react';
import gsap from "gsap";
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);



export default function InformationComponent() {

//Animation Fade In
const animateInfo = useRef();
useEffect(() => {
  let ctx = gsap.context(() => {
    let whoWeAre = gsap.timeline({
      scrollTrigger: {
        trigger: '.who_we_are',
        start: 'top 30%',
        end: '90% top',
        // markers: true
      },
    })
    whoWeAre.from('.who_we_are .txt', {duration:1, opacity: 0, y: 50, ease: 'power4.out' });
  }, [animateInfo]);
  return () => ctx.revert();
}, []);




  return (<section className="who_we_are">
    <div className="txt">
      <h2 className="head">GU SPACE</h2>
      <span className="txtsub">(Pronounced G-U-SPACE) </span>
      <h2>is a platform that will <span className="t_inline">assist you</span> in renting a space in the old
        town for <span className="t_inline">your special</span> event.
      </h2>
      <p>GU Space (อ่านว่า จี-ยู-สเปส)
        <span className="t_inline">คือแพลตฟอร์ม</span>
        <span className="t_inline">ที่จะช่วย</span>
        <span className="t_inline">ดำเนินความสะดวก</span>ให้คุณสามารถเช่าพื้นที่ใน
        <span className="t_inline">ย่านเมืองเก่า</span> เพื่อจัดกิจกรรมพิเศษของคุณได้ง่ายๆ
      </p>
    </div>
  </section>)
}
