import { useState, useEffect, useContext } from "react";
import { space_filter } from "utils/services/api/schemas";
import CommonServives from "utils/services/api/common";
import gsap from "gsap";
import history from "utils/history";
import { Loading } from "utils/services/store";

export default function SpacesFunction() {
  const { setIsLoading } = useContext(Loading);
  const [spaces, setSpaces] = useState([]);
  const [spacesData, setSpacesData] = useState({});
  const [spaceType, setSpaceType] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionsData, setSuggestionsData] = useState({});
  const [location, setLocation] = useState({});
  const [showMap, setShowMap] = useState(true);
  const [center, setCenter] = useState({ lat: 13.736717, lng: 100.523186 });
  const [markRender, setMarkRender] = useState(true);
  const { getList } = CommonServives();

  const rawSearchParams = decodeURIComponent(history.location.search);

  useEffect(() => {
    GetSpaceData(rawSearchParams);

    gsap.to("body", {
      duration: 2,
      opacity: 1,
      delay: 1,
      ease: "power3.out",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getParams() {
    const searchParams = new URLSearchParams(history.location.search);
    const params = {};
    for (const [key, value] of searchParams.entries()) {
      params[key] = value;
      if (key === "event") {
      }
    }
    return params;
  }

  async function getSpace(filter, params, other = "") {
    return await getList(`${space_filter}?${filter}=${params}${other}`, {}, true)
      .then((r) => {
        return r.data;
      })
      .catch((e) => {
        return null;
      });
  }

  async function getSuggestions(spaces) {
    let params = getParams();
    params = { ...params, limit: 10 };
    if (!spaces) {
      try {
        let filters = ["location", "event", "max_people", "selected_date", "limit"];
        for (let i = 0; i < filters.length; i++) {
          let filter = filters[i];
          if (params[filter]) {
            let data = await getSpace(filter, params[filter], filter !== "limit" ? "&limit=10" : "");
            if (data) {
              setSuggestions(data);
              setSuggestionsData({
                filter: filter,
                key: params[filter],
              });
              break;
            }
          } else {
            continue;
          }
        }
      } catch (error) {
        alert(error);
      }
    } else {
      setSuggestions({
        space: [],
      });
      setSuggestionsData(null);
    }
  }

  function setDefaultSpaceType(spaces) {
    if (spaces) {
      let spaceData = spaces.space;
      let spaceType = [];
      spaceData.map((space) => {
        if (spaceType.indexOf(space.space_type) === -1) {
          spaceType.push(space.space_type);
        }
      });
      setSpaceType(spaceType);
    }
  }

  function GetSpaceData(searchParams) {
    setIsLoading(true);
    getList(`${space_filter}${searchParams}`, {}, true)
      .then((response) => {
        //getSuggestions(response.data)
        if (response.data) {
          setSpacesData(response.data);
          getSuggestions(response.data);
          setDefaultSpaceType(response.data);
          setSpaces(response.data.space);
        }
        setTimeout(() => {
          setIsLoading(false);
        }, 900);
      })
      .catch((err) => {
        setSpaces([]);
        setSpacesData({
          space: [],
        });
        getSuggestions(null);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      });
  }

  const handleHover = (data) => {
    setMarkRender(false);
    setCenter({ lat: data.lat, lng: data.long });
    setTimeout(() => {
      setLocation(data);
    }, 1000);

    setTimeout(() => {
      setMarkRender(true);
    }, 1000);
  };
  const handleLeave = () => {
    setLocation({});
  };

  const handleSpaceFilter = (filters) => {
    setIsLoading(true);
    try {
      //console.log(filters)
      let spaceDataUse = spaces;
      let spacesFilter = spaceDataUse.space;
      if (filters.length > 0) {
        spacesFilter = spaces.filter((space) => {
          return filters.includes(space.space_type);
        });
        spaceDataUse.space = spacesFilter;
      } else {
        spaceDataUse.space = [];
      }
      //console.log(spaceDataUse)
      setSpacesData(spaceDataUse);
      if (spaceDataUse.space && spaceDataUse.space.length <= 0) {
        //console.log();
        getSuggestions(null);
      }
    } catch (error) {
      setSpacesData({
        space: [],
      });
      setSpaces([]);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const handleAdvanceFilter = (filterObject) => {
    setIsLoading(true);
    try {
      let typeFilter = filterObject.spaces;
      let amenityFilter = filterObject.amenities;
      let featureFilter = filterObject.features;
      let fromPriceFilter = filterObject.fromPrice;
      let toPriceFilter = filterObject.toPrice;
      let spaceDataUse = spaces;
      let spacesFilter = spaceDataUse.space;
      if (typeFilter.length > 0) {
        spacesFilter = spaces.filter((space) => {
          return typeFilter.includes(space.space_type);
        });
        spaceDataUse.space = spacesFilter;
      }
      if (amenityFilter.length > 0) {
        spacesFilter = spacesFilter.filter((space) => {
          let amenities = space.amenities;
          return amenityFilter.every((amenity) => {
            return amenities.includes(amenity);
          });
        });
      }
      if (featureFilter.length > 0) {
        spacesFilter = spacesFilter.filter((space) => {
          let features = space.highlight_features;
          return featureFilter.every((feature) => {
            return features.includes(feature);
          });
        });
      }
      if (fromPriceFilter !== "" && fromPriceFilter !== undefined && fromPriceFilter !== null) {
        spacesFilter = spacesFilter.filter((space) => {
          let price = space.min_price;
          return Number(price) >= Number(fromPriceFilter);
        });
      }
      if (toPriceFilter !== "" && toPriceFilter !== undefined && toPriceFilter !== null) {
        spacesFilter = spacesFilter.filter((space) => {
          let price = space.max_price;
          return Number(price) <= Number(toPriceFilter);
        });
      }
      spaceDataUse.space = spacesFilter;
      setSpacesData(spaceDataUse);
      if (spaceDataUse.space && spaceDataUse.space.length <= 0) {
        getSuggestions(null);
      }
    } catch (error) {
      setSpacesData({
        space: [],
      });
      setSpaces([]);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  return {
    showMap,
    location,
    spacesData,
    setShowMap,
    suggestions,
    suggestionsData,
    spaceType,
    center,
    markRender,
    handleSpaceFilter,
    handleAdvanceFilter,
    handleLeave,
    handleHover,
    GetSpaceData,
  };
}
