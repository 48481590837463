import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { MotionPathPlugin } from 'gsap/MotionPathPlugin'
import { Swiper } from 'swiper'
import $ from 'jquery'
import 'jquery.cycle2'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/effect-cards'

gsap.registerPlugin(ScrollTrigger, MotionPathPlugin)
const herobannerThumb = gsap.timeline({ delay: 1 })

const startAnimation = () => {
  gsap.registerPlugin(MotionPathPlugin, ScrollTrigger)
  let window2 = null
  let window3 = null
  let window1 = gsap.timeline({
    //เปิดหน้าต่าง
    scrollTrigger: {
      trigger: '.visit',
      start: '20% center',
      end: 'bottom bottom',
      once: true,
      scrub: false,
      // markers: true,
    },
  })

  setTimeout(() => {
    window1
      .to('.window1_left', 1, {
        transform: 'perspective(1000px)',
        rotateY: -125,
        transformOrigin: '15.7% 50%',
        ease: 'power2.inOut',
      })
      .to(
        '.window1_right',
        1,
        {
          transform: 'perspective(1000px)',
          rotateY: 125,
          transformOrigin: '84.3% 50%',
          ease: 'power2.inOut',
        },
        '<'
      )
    window2 = gsap
      .timeline({ delay: 0.7, paused: true })
      .to('.window2_left', 1, {
        transform: 'perspective(1000px)',
        rotateY: -125,
        transformOrigin: '21.7% 50%',
        ease: 'power2.inOut',
      })
      .to(
        '.window2_right',
        1,
        {
          transform: 'perspective(1000px)',
          rotateY: 125,
          transformOrigin: '78.3% 50%',
          ease: 'power2.inOut',
        },
        '<'
      )

    window3 = gsap
      .timeline({ delay: 0.7, paused: true })
      .to('.window3_left', 1, {
        transform: 'perspective(1000px)',
        rotateY: -125,
        transformOrigin: '16.5% 50%',
        ease: 'power2.inOut',
      })
      .to(
        '.window3_right',
        1,
        {
          transform: 'perspective(1000px)',
          rotateY: 125,
          transformOrigin: '84.5% 50%',
          ease: 'power2.inOut',
        },
        '<'
      )
  }, 1000)

  let swiperVisit = new Swiper(
    '#slide_visit',
    {
      slidesPerView: 1,
      observer: true,
      observeParents: true,
      centeredSlides: true,
      speed: 600,
      navigation: {
        nextEl: '#swipernext',
        prevEl: '#swiperprev',
      },
      breakpoints: {
        640: {
          slidesPerView: 1.3,
        },
        768: {
          slidesPerView: 1.6,
        },
        1280: {
          slidesPerView: 2.2,
        },
      },
    },
    []
  )
  $(function () {
    if (swiperVisit.activeIndex === 0) {
      $('#swiperprev').hide()
    } else {
      $('#swiperprev').show()
    }
  })
  $('#swipernext').on('click', function () {
    swiperVisit.slideNext()
  })
  $('#swiperprev').on('click', function () {
    swiperVisit.slidePrev()
  })

  swiperVisit.on('slideChange', function () {
    if (swiperVisit.activeIndex === 0) {
      $('#swiperprev').hide()
    } else {
      $('#swiperprev').show()
    }

    if (swiperVisit.activeIndex === 4) {
      $('#swipernext').hide()
    } else {
      $('#swipernext').show()
    }

    let windowIndex = this.activeIndex + 1

    if (windowIndex === 1) {
      window1.restart()
      window2.timeScale(-1.5).reverse()
      window3.timeScale(-1.5).reverse()
    }
    if (windowIndex === 2) {
      window1.timeScale(-1.5).reverse()
      window2.restart()
      window3.timeScale(-1.5).reverse()
    }
    if (windowIndex === 3) {
      window1.timeScale(-1.5).reverse()
      window2.timeScale(-1.5).reverse()
      window3.restart()
    }

    if (windowIndex === 4) {
      window1.restart()
      window2.timeScale(-1.5).reverse()
      window3.timeScale(-1.5).reverse()
    }

    if (windowIndex === 5) {
      window1.timeScale(-1.5).reverse()
      window2.restart()
      window3.timeScale(-1.5).reverse()
    }
  })

  $('.content-looking .item').click(function () {
    $(this).toggleClass('active')
  })

  $('.content-tags .tags .tag .close').click(function () {
    $(this).parent('.tag').remove()
  })

  $('.facilities .lists li').each(function () {
    let nameList = $(this).attr('data-facilities')
    let nameIcon = $(this).attr('data-icon-name')
    $(this).html(nameList)
    $(this).css('background-image', 'url(' + nameIcon + ')')
  })

  // setTimeout(function () {
  //   //Drop Down
  //   var x, i, j, l, ll, selElmnt, a, b, c
  //   /* Look for any elements with the class "custom-select": */
  //   x = document.getElementsByClassName('custom-select')
  //   l = x.length
  //   for (i = 0; i < l; i++) {
  //     selElmnt = x[i].getElementsByTagName('select')[0]
  //     ll = selElmnt.length
  //     /* For each element, create a new DIV that will act as the selected item: */
  //     a = document.createElement('DIV')
  //     a.setAttribute('class', 'select-selected')
  //     a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML
  //     x[i].appendChild(a)
  //     /* For each element, create a new DIV that will contain the option list: */
  //     b = document.createElement('DIV')
  //     b.setAttribute('class', 'select-items select-hide')
  //     for (j = 1; j < ll; j++) {
  //       /* For each option in the original select element,
  //       create a new DIV that will act as an option item: */
  //       c = document.createElement('DIV')
  //       c.innerHTML = selElmnt.options[j].innerHTML
  //       c.addEventListener('click', function (e) {
  //         /* When an item is clicked, update the original select box,
  //       and the selected item: */
  //         var y, i, k, s, h, sl, yl
  //         s = this.parentNode.parentNode.getElementsByTagName('select')[0]
  //         sl = s.length
  //         h = this.parentNode.previousSibling
  //         for (i = 0; i < sl; i++) {
  //           if (s.options[i].innerHTML === this.innerHTML) {
  //             s.selectedIndex = i
  //             h.innerHTML = this.innerHTML
  //             y = this.parentNode.getElementsByClassName('same-as-selected')
  //             yl = y.length
  //             for (k = 0; k < yl; k++) {
  //               y[k].removeAttribute('class')
  //             }
  //             this.setAttribute('class', 'same-as-selected')
  //             break
  //           }
  //         }
  //         h.click()
  //       })
  //       b.appendChild(c)
  //     }
  //     x[i].appendChild(b)
  //     a.addEventListener('click', function (e) {
  //       /* When the select box is clicked, close any other select boxes,
  //     and open/close the current select box: */
  //       e.stopPropagation()
  //       closeAllSelect(this)
  //       this.nextSibling.classList.toggle('select-hide')
  //       this.classList.toggle('select-arrow-active')
  //     })
  //   }

  //   function closeAllSelect(elmnt) {
  //     /* A function that will close all select boxes in the document,
  //     except the current select box: */
  //     var x,
  //       y,
  //       i,
  //       xl,
  //       yl,
  //       arrNo = []
  //     x = document.getElementsByClassName('select-items')
  //     y = document.getElementsByClassName('select-selected')
  //     xl = x.length
  //     yl = y.length
  //     for (i = 0; i < yl; i++) {
  //       if (elmnt === y[i]) {
  //         arrNo.push(i)
  //       } else {
  //         y[i].classList.remove('select-arrow-active')
  //       }
  //     }
  //     for (i = 0; i < xl; i++) {
  //       if (arrNo.indexOf(i)) {
  //         x[i].classList.add('select-hide')
  //       }
  //     }
  //   }

  //   document.addEventListener('click', closeAllSelect)
  // }, 50)

  $('.thumb').each(function (i) {
    let delay = i * 150
    $(this).cycle({
      delay: delay,
      speed: 1000,
      timeout: 4000,
    })
  })

  herobannerThumb
    .from('.herobanner .inner .txt', 0.8, { opacity: 0, ease: 'power4.out' })
    .from('.herobanner .inner .txt', 0.8, { y: 100, ease: 'power4.out' })
    .from('.herobanner .group_search', 1.6, { opacity: 0, ease: 'power4.out' }, '<')
    .from('.line_any svg path', 0.8, { drawSVG: 0, ease: 'none' }, '<0.3')
    .from('.thumb01', 1.2, { opacity: 0, scale: 0, ease: 'power4.out' }, '<0.9')
    .from('.thumb02', 1.2, { opacity: 0, scale: 0, ease: 'power4.out' }, '<0.1')
    .from('.thumb03', 1.2, { opacity: 0, scale: 0, ease: 'power4.out' }, '<0.1')
    .from('.thumb04', 1.2, { opacity: 0, scale: 0, ease: 'power4.out' }, '<0.1')
    .from('.thumb05', 1.2, { opacity: 0, scale: 0, ease: 'power4.out' }, '<0.1')
    .from('.thumb06', 1.2, { opacity: 0, scale: 0, ease: 'power4.out' }, '<0.1')
    .from('.thumb07', 1.2, { opacity: 0, scale: 0, ease: 'power4.out' }, '<0.1')
  ScrollTrigger.refresh(true)

  let scrollDownThumb = gsap.timeline({
    scrollTrigger: {
      trigger: '.herobanner',
      start: 'top top',
      end: '90% top',
      scrub: 0.2,
      // markers: true
    },
  })
  let mm = gsap.matchMedia()
  mm.add('(min-width: 992px)', () => {
    scrollDownThumb
      .to('.thumb01', 1, {
        y: $('.herobanner').outerHeight() * 0.35,
        ease: 'none',
      })
      .to('.thumb02', 1, { y: $('.herobanner').outerHeight() * 0.6, ease: 'none' }, '<')
      .to('.thumb03', 1, { y: $('.herobanner').outerHeight() * 0.9, ease: 'none' }, '<')
      .to('.thumb04', 1, { y: $('.herobanner').outerHeight() * 0.6, ease: 'none' }, '<')
      .to('.thumb05', 1, { y: $('.herobanner').outerHeight() * 0.55, ease: 'none' }, '<')
      .to('.thumb06', 1, { y: $('.herobanner').outerHeight() * 1.15, ease: 'none' }, '<')
      .to('.thumb07', 1, { y: $('.herobanner').outerHeight() * 0.6, ease: 'none' }, '<')
  })

  mm.add('(max-width: 991px)', () => {
    scrollDownThumb
      .to('.thumb01', 1, { y: 35, ease: 'none' })
      .to('.thumb02', 1, { y: -60, ease: 'none' }, '<')
      .to('.thumb03', 1, { y: 125, ease: 'none' }, '<')
      .to('.thumb04', 1, { y: 150, ease: 'none' }, '<')
      .to('.thumb05', 1, { y: 55, ease: 'none' }, '<')
      .to('.thumb06', 1, { y: 95, ease: 'none' }, '<')
      .to('.thumb07', 1, { y: -50, ease: 'none' }, '<')
  })

  let whoWeAre = gsap.timeline({
    scrollTrigger: {
      trigger: '.who_we_are',
      start: 'top 25%',
      end: '90% top',
      // scrub: 0.5,
      // markers: true
    },
  })

  whoWeAre.from('.who_we_are .txt', 1, { opacity: 0, y: 40, ease: 'power4.out' })
  let planning = gsap.timeline({
    scrollTrigger: {
      trigger: '.planning',
      start: 'top 50%',
      end: 'bottom top',
      // scrub: 0.5,
      // markers: true,
    },
  })
  planning
    .from('.planning .txt', 1, { opacity: 0, y: 40, ease: 'power4.out' })
    .to(
      '.planning .txt .dot',
      1.25,
      {
        visibility: 'visible',
        motionPath: {
          path: '.path path',
          align: '.path path',
          autoRotate: true,
          alignOrigin: [0.5, 0.5],
        },
        ease: 'none',
      },
      '<0.5'
    )
    .from('.planning .txt svg.line3 path', 1.25, { drawSVG: 0, ease: 'none' }, '<0')
  let lineStory = gsap.timeline({
    scrollTrigger: {
      trigger: '.story .scene1 .txt',
      start: 'top 50%',
      end: '40% top',
      //   scrub: 0.5,
      // markers: true,
    },
  })
  lineStory.from('.line_story svg path', 1, { drawSVG: 0, ease: 'none' })

  //Story
  let storyScene1 = gsap.timeline({
    scrollTrigger: {
      trigger: '.story',
      start: 'top 50%',
      end: '40% top',
      scrub: 0.5,
      // markers: true,
    },
  })

  let storyScene3 = gsap.timeline({
    scrollTrigger: {
      trigger: '.story .scene3',
      start: 'top 50%',
      end: '100% 50%',
      scrub: 0.5,
      // markers: true,
    },
  })

  let storyScene4 = gsap.timeline({
    scrollTrigger: {
      trigger: '.story .scene4',
      start: 'top 50%',
      end: '50% 50%',
      scrub: 0.5,
      // markers: true,
    },
  })
  let mm_story = gsap.matchMedia()
  mm_story.add('(min-width: 992px)', () => {
    storyScene1
      .from('.sticky .city', 0.5, {
        opacity: 0,
        scale: 0.8,
        ease: 'power4.out',
      })
      //   .from(".story .txt", 0.5, {
      //     opacity: 0,
      //     y: 50,
      //     ease: "power4.out",
      //   })
      .from(
        '.sticky .location_story1',
        0.5,
        {
          scale: 0,
          stagger: 0.15,
          ease: 'back.out',
        },
        '<0.1'
      )
      .to('.sticky .location_story1', 0.5, {
        scale: 0,
        stagger: 0.15,
        ease: 'back.in',
        delay: 0.1,
      })
      .to(
        '.sticky .city',
        0.5,
        {
          scale: 1.05,
          ease: 'power4.in',
        },
        '<0.5'
      )
      .from(
        '.sticky .location_story2',
        0.5,
        {
          scale: 0,
          stagger: 0.15,
          ease: 'back.out',
        },
        '<0.5'
      )
      .to('.sticky .location_story2', 0.5, {
        scale: 0,
        stagger: 0.15,
        ease: 'back.in',
        delay: 0.1,
      })
      .to('.sticky .city', 0.5, {
        scale: 0.9,
        opacity: 0,
        ease: 'power4.out',
      })

    storyScene3
      .from('.sticky .story_photo1', 1, { opacity: 0, scale: 1, y: 170, x: -50 })
      .from('.sticky .story_photo2', 1, { opacity: 0, scale: 1, y: -170, x: 0 }, '<0.1')
      .from('.sticky .story_photo3', 1, { opacity: 0, scale: 1, y: -50, x: 100 }, '<0.1')
      .from('.sticky .story_photo4', 1, { opacity: 0, scale: 1, y: 120, x: 50 }, '<0.1')
      .to('.sticky .story_photo1', 1, { opacity: 0, y: 170, x: -50, delay: 1 })
      .to('.sticky .story_photo2', 1, { opacity: 0, y: -170, x: 0 }, '<0.1')
      .to('.sticky .story_photo3', 1, { opacity: 0, y: -50, x: 100 }, '<0.1')
      .to('.sticky .story_photo4', 1, { opacity: 0, y: 120, x: 50 }, '<0.1')

    storyScene4
      .from('.sticky .ipad', 1, { opacity: 0, y: -120, ease: 'none' })
      .from('.sticky .platform svg path', 1, { drawSVG: 0, ease: 'none' }, '<0.8')
  })

  $('.sticky .location_story1').each(function (i) {
    gsap.from(this, 0.4, {
      boxShadow: '0px 0px 0px 8px rgba(251, 255, 37, 0.1)',
      yoyo: true,
      repeat: -1,
      delay: 0.1 * i,
    })
  })

  $('.sticky .location_story2').each(function (i) {
    gsap.from(this, 0.4, {
      boxShadow: '0px 0px 0px 12px rgba(251, 255, 37, 0.1)',
      yoyo: true,
      repeat: -1,
      delay: 0.1 * i,
    })
  })
  let addClassOnScroll = function () {
    let windowTop = $(window).scrollTop()
    $('.story .item_txt').each(function (index, elem) {
      let offsetTop = $(elem).offset().top
      let outerHeight = $(this).outerHeight(true)

      if (windowTop > offsetTop - 50 && windowTop < offsetTop + outerHeight) {
        $('.dot_scene span.active').removeClass('active')
        $('.dot_scene span').eq(index).addClass('active')
      }
    })
  }
  $(window).on('scroll', function () {
    addClassOnScroll()
  })

  function goToByScroll(id) {
    let sectionId = id.replace('btn_', '')
    $('html,body').animate(
      {
        scrollTop: $('.' + sectionId).offset().top,
      },
      1000
    )
  }

  $('.dot_scene span').click(function (e) {
    e.preventDefault()
    goToByScroll(this.id)

    $('.dot_scene span').removeClass('active')
    $(this).addClass('active')
  })
  let img_mb_scene1 = gsap.timeline({
    scrollTrigger: {
      trigger: '.img_mb_scene1',
      start: 'top 60%',
      end: '100% 60%',
      scrub: false,
      // markers: true,
    },
  })

  img_mb_scene1
    .from('.img_mb_scene1 .city img', 0.8, { scale: 0, ease: 'back.out' })
    .from('.img_mb_scene1 .location_story1', 0.5, { scale: 0, stagger: 0.15, ease: 'back.out' }, '<0.3')

  let img_mb_scene2 = gsap.timeline({
    scrollTrigger: {
      trigger: '.img_mb_scene2',
      start: 'top 60%',
      end: '100% 60%',
      scrub: false,
      // markers: true,
    },
  })

  img_mb_scene2
    .from('.img_mb_scene2 .city img', 0.8, { scale: 0, ease: 'back.out' })
    .from('.img_mb_scene2 .location_story2', 0.5, { scale: 0, stagger: 0.15, ease: 'back.out' }, '<0.3')

  let img_mb_scene3 = gsap.timeline({
    scrollTrigger: {
      trigger: '.img_mb_scene3',
      start: 'top 60%',
      end: '100% 60%',
      scrub: false,
      // markers: true,
    },
  })

  img_mb_scene3
    .from('.img_mb_scene3 .story_photo1', 1, { opacity: 0, scale: 1, y: 170, x: -50, ease: 'power4.out' })
    .from('.img_mb_scene3 .story_photo2', 1, { opacity: 0, scale: 1, y: -170, x: 0, ease: 'power4.out' }, '<0.1')
    .from('.img_mb_scene3 .story_photo3', 1, { opacity: 0, scale: 1, y: -50, x: 100, ease: 'power4.out' }, '<0.1')
    .from('.img_mb_scene3 .story_photo4', 1, { opacity: 0, scale: 1, y: 120, x: 50, ease: 'power4.out' }, '<0.1')

  let img_mb_scene4 = gsap.timeline({
    scrollTrigger: {
      trigger: '.img_mb_scene4',
      start: 'top 60%',
      end: '100% 60%',
      scrub: false,
      // markers: true,
    },
  })

  img_mb_scene4
    .from('.img_mb_scene4 .ipad', 1, { opacity: 0, y: 120, ease: 'power4.out' })
    .from('.img_mb_scene4 .platform svg path', 1, { drawSVG: 0, ease: 'power1.out' }, '<0.5')
}

export default startAnimation
