import React, { useEffect } from "react";
import gsap from "gsap";
import Animation from "utils/animate";

import NavBarHeader from "components/global/navbarspace";
import FooterComponent from "components/global/footer";
import useFunction from "./function";

import DetailPage from "./detail";
import BookingPage from "./booking";
import ThankYouPage from "./thankyou";

export default function Index() {
  const {
    currPage,
    resultParams,
    masterData,
    detailData,
    freeSlots,
    setFreeSlots,
    sumOrderHours,
    sumOrderPrice,
    sumOrderProcessPrice,
    selectdRange,
    selectedDate,
    onSelectTimeRange,
    onChangeExpectArriavalTime,
    onSelectDate,
    onAddedExtended,
    onDeleteExtended,
    onChangePage,
    findDuplicateSelected,
    setResultStructure,
    setCurrPage,
  } = useFunction();

  const currentPage = [
    <DetailPage
      findDuplicateSelected={findDuplicateSelected}
      resultParams={resultParams}
      sumOrderHours={sumOrderHours}
      sumOrderPrice={sumOrderPrice}
      sumOrderProcessPrice={sumOrderProcessPrice}
      detailData={detailData}
      freeSlots={freeSlots}
      selectdRange={selectdRange}
      selectedDate={selectedDate}
      onSelectTimeRange={onSelectTimeRange}
      onSelectDate={onSelectDate}
      onAddedExtended={onAddedExtended}
      onDeleteExtended={onDeleteExtended}
      onChangePage={onChangePage}
    />,
    <BookingPage
      findDuplicateSelected={findDuplicateSelected}
      resultParams={resultParams}
      sumOrderHours={sumOrderHours}
      sumOrderPrice={sumOrderPrice}
      sumOrderProcessPrice={sumOrderProcessPrice}
      masterData={masterData}
      onSelectDate={onSelectDate}
      onSelectTimeRange={onSelectTimeRange}
      onChangeExpectArriavalTime={onChangeExpectArriavalTime}
      detailData={detailData}
      onChangePage={onChangePage}
      setResultStructure={setResultStructure}
      freeSlots={freeSlots}
      setFreeSlots={setFreeSlots}
    />,
    <ThankYouPage resultParams={resultParams} />,
  ];

  useEffect(() => {
    if (detailData !== {}) {
      let isBookData = localStorage.getItem("book_data");
      if (window.location.search && window.location.search.split("currentPage=").length > 1 && isBookData) {
        let curr_page = parseInt(window.location.search.split("currentPage=")[1]);
        setCurrPage(curr_page);
        setTimeout(() => {
          setCurrPage(curr_page);
          console.log("curr_page", curr_page);
        }, 1000);
      } else {
        const params = new URLSearchParams(window.location.search);
        params.delete("query");
        const newSearch = params.toString();
        const newUrl = window.location.pathname + (newSearch ? `?${newSearch}` : "");
        window.history.replaceState(null, "", newUrl);
      }
    }
  }, [detailData]);

  useEffect(() => {
    Animation();

    if (detailData !== {}) {
      gsap.to("body", {
        duration: 1,
        opacity: 1,
        delay: 0,
        ease: "power3.out",
      });
    }
  }, [currPage, detailData]);

  return (
    <div className="wrapper">
      <NavBarHeader reqDetail={false} />
      {currentPage[currPage || 0]}
      <FooterComponent />
    </div>
  );
}
