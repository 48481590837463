import React, { useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, EffectCards } from 'swiper'
import $ from 'jquery'
import 'jquery.cycle2'
import './style.css'
import { useNavigate } from 'react-router-dom'
import useFunction from './function'
export default function SpaceMomentComponent() {
  const swiperRef = useRef(null)
  const navigate = useNavigate()
  const { events } = useFunction()
  function handleMouseEnter(index) {
    let moment_activity = document.querySelectorAll('.moment_activity li')
    $(moment_activity).removeClass('active')
    $(this).addClass('active')
    swiperRef.current.swiper.slideTo(index, 1000, false)
  }

  function handleMouseLeave() {}

  React.useEffect(() => {
    let moment_activity = document.querySelectorAll('.moment_activity li')
    moment_activity.forEach(function (button, i) {
      button.addEventListener('mouseover', function () {
        $(moment_activity).removeClass('active')
        $(this).addClass('active')
        return swiperRef.current.swiper.slideTo(i, 1000, false)
      })
    })
  }, [])
  return (
    <section className="moment">
      <div className="inner">
        <div className="txt">
          <h2>
            A
            <br />
            space
            <br />
            for{' '}
            <span className="line_every">
              {' '}
              every
              <svg width="321" height="30" viewBox="0 0 321 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4.71021 18.297C1.07741 17.9811 12.5346 13.7031 13.1074 13.464C21.5901 9.92296 31.6046 5.31581 41.505 4.06346C47.3549 3.32347 43.1844 18.7797 43.1844 22.5989C43.1844 31.8029 52.251 24.1865 58.6046 21.1649C60.4895 20.2685 84.1822 8.79339 84.4067 11.6051C84.7189 15.514 84.2013 20.7882 89.216 23.5018C95.939 27.1398 108.146 19.2952 112.575 16.8099C114.937 15.485 132.499 3.8266 132.499 8.73715C132.499 12.896 133.952 19.7888 142.118 18.0845C154.415 15.5179 162.816 5.64096 175.707 4.01035C178.297 3.68264 179.677 11.2352 180.592 12.986C183.061 17.7095 202.928 12.4753 206.394 11.6051C212.154 10.1593 225.97 2.40349 228.38 8.73715C233.296 21.6582 249.701 15.4487 262.961 14.5262C265.654 14.3388 266.933 14.7038 269.144 15.2166C276.397 16.8986 289.47 9.92921 295.709 8.20605C302.245 6.40121 318 0.291592 318 7.78117"
                  stroke="#FBFF25"
                  strokeWidth="6"
                  strokeLinecap="round"
                />
              </svg>
            </span>
            <br />
            moment
          </h2>
          <p>พื้นที่สำหรับทุกช่วงเวลาพิเศษของคุณ</p>

          <ul className="moment_activity">
            {events &&
              events.map((event, index) => {
                return (
                  <li
                    key={index}
                    style={{ textTransform: 'capitalize' }}
                    onMouseEnter={() => {
                      handleMouseEnter(index)
                    }}
                    onMouseLeave={handleMouseLeave}
                    className={index === 0 ? 'active ' : ''}
                    onClick={(e) => {
                      window.location.href = `/spaces?event=${event.name}`
                    }}
                  >
                    {event.name} <span className="capitalize"></span><span>({event.count})</span>
                  </li>
                )
              })}
            {/* <li className="active" onClick={(e)=>{ window.location.href = `/spaces?event=photoshoot` }} >
                Photoshoot 
               <span>28 พื้นที่</span>
            </li>
            <li  onClick={(e)=>{ window.location.href = `/spaces?event=meeting` }}>
              Meeting <span>14 พื้นที่</span>
            </li>
            <li  onClick={(e)=>{ window.location.href = `/spaces?event=corporate` }}>
              Corporate <span>25 พื้นที่</span>
            </li>
            <li  onClick={(e)=>{ window.location.href = `/spaces?event=workshop` }}>
              Workshop <span>40 พื้นที่</span>
            </li>
            <li  onClick={(e)=>{ window.location.href = `/spaces?event=wedding` }}>
              Wedding <span>22 พื้นที่</span>
            </li>
            <li  onClick={(e)=>{ window.location.href = `/spaces?event=party` }}>
              Party <span>32 พื้นที่</span>
            </li> */}
          </ul>
        </div>
        <div className="img">
          <Swiper
            ref={swiperRef}
            className="swiper slide_moment   "
            effect={'cards'}
            modules={[Navigation, EffectCards]}
            navigation={false}
            pagination={{ clickable: false }}
          >
            <SwiperSlide>
              <div className="thumb">
                <img src="assets/images/picture_moment/moment01.jpg" alt="images" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="thumb">
                <img src="assets/images/picture_moment/moment02.jpg" alt="images" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="thumb">
                <img src="assets/images/picture_moment/moment03.jpg" alt="images" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="thumb">
                <img src="assets/images/picture_moment/moment04.jpg" alt="images" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="thumb">
                <img src="assets/images/picture_moment/moment05.jpg" alt="images" />{' '}
              </div>
            </SwiperSlide>
            <SwiperSlide>
              {' '}
              <div className="thumb">
                <img src="assets/images/picture_moment/moment06.jpg" alt="images" />{' '}
              </div>
            </SwiperSlide>
          </Swiper>

          {/* <div className="swiper slide_moment ">
            <div  className="swiper-wrapper swiper-wrapper-card  "> 
              <div  >
                <div  className="thumb">
                  <img id='thumb1' src="assets/images/picture_moment/moment01.jpg" alt="" />
                </div>
              </div>
              <div  >
                <div className="thumb">
                  <img  id='thumb2' src="assets/images/picture_moment/moment02.jpg" alt="" />
                </div>
              </div> 
              <div  >
                <div className="thumb">
                  <img  id='thumb3' src="assets/images/picture_moment/moment03.jpg" alt="" />
                </div>
              </div>
              <div  >
                <div className="thumb">
                  <img  id='thumb4' src="assets/images/picture_moment/moment04.jpg" alt="" />
                </div>
              </div>
              <div  >
                <div className="thumb">
                  <img  id='thumb5' src="assets/images/picture_moment/moment05.jpg" alt="" />
                </div>
              </div>
              <div  >
                <div className="thumb">
                  <img  id='thumb6' src="assets/images/picture_moment/moment06.jpg" alt="" />
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  )
}
