import React from 'react'


export default function StoryComponent() {
  return (
    <section className="story">
                <div className="inner">
                    <div className="scene">
                        <div className="col">
                            <div className="item item_txt scene1">
                                <div className="txt">
                                    <h2>What is <span className="line_story"><svg width="163" height="17"
                                                viewBox="0 0 163 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M2.35963 10.0809C0.52009 9.90234 6.32165 7.48434 6.61169 7.34919C10.9071 5.34776 15.9781 2.74372 20.9914 2.03587C23.9536 1.61761 21.8418 10.3538 21.8418 12.5124C21.8418 17.7147 26.4328 13.4097 29.6501 11.7019C30.6046 11.1953 42.6018 4.70931 42.7155 6.29854C42.8736 8.5079 42.6115 11.489 45.1508 13.0227C48.5551 15.079 54.7363 10.6451 56.9792 9.24038C58.175 8.49152 67.0682 1.90199 67.0682 4.67752C67.0682 7.02816 67.804 10.9241 71.9387 9.96083C78.1657 8.51011 82.4195 2.9275 88.947 2.00585C90.2588 1.82062 90.9575 6.08948 91.4209 7.07903C92.671 9.74884 102.731 6.79036 104.486 6.29854C107.403 5.48133 114.399 1.09762 115.619 4.67752C118.108 11.9807 126.415 8.47099 133.13 7.94957C134.493 7.84366 135.141 8.04996 136.261 8.33981C139.933 9.29049 146.553 5.35129 149.713 4.37733C153.022 3.35721 161 -0.0960566 161 4.13718"
                                                    stroke="#FBFF25" strokeWidth="3" strokeLinecap="round" />
                                            </svg>
                                            GU Space?</span></h2>
                                    <p>ถ้าให้คุณนึกถึง<span className="txtbold">ย่านเมืองเก่า</span><br />คุณจะนึกถึงอะไร</p>
                                    <p>คุณอาจจะนึกถึง<br /><span className="txtbold">แหล่งท่องเที่ยว เช่น</span><br /><span
                                            className="txtbold">เสาชิงช้า ภูเขาทอง</span><br /><span className="txtbold">เยาวราช
                                            สนามหลวง</span></p>
                                </div>
                                <div className="img_mb img_mb_scene1">
                                    <div className="img">
                                        <div className="city">
                                            <img src="assets/images/story_city.svg" alt="" />
                                        </div>
                                        <div className="location_story1 location_s1_location01 pos">
                                            <img src="assets/images/story_scene1_location01.png" alt="" />
                                        </div>
                                        <div className="location_story1 location_s1_location02 pos">
                                            <img src="assets/images/story_scene1_location02.png" alt="" />
                                        </div>
                                        <div className="location_story1 location_s1_location03 pos">
                                            <img src="assets/images/story_scene1_location03.png" alt="" />
                                        </div>
                                        <div className="location_story1 location_s1_location04 pos">
                                            <img src="assets/images/story_scene1_location04.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item item_txt scene2">
                                <div className="txt">
                                    <p>แต่ถ้ามองลึกลงไปคุณ<br />จะเห็น<span className="txtbold">พื้นที่สวยๆซ่อนอยู่</span></p>
                                    <p><span className="txtbold">อาจจะเป็นบ้านเรือน ห้องโถง</span><br /><span
                                            className="txtbold">ดาดฟ้า หรือสวนหลังบ้าน</span></p>
                                    <p>พื้นที่เหล่านี้สวยและแตกต่าง<br />ด้วยสถาปัตยกรรมที่มี<br />มานานกว่า 200 ปี</p>
                                </div>
                                <div className="img_mb img_mb_scene2">
                                    <div className="img">
                                        <div className="city">
                                            <img src="assets/images/story_city.svg" alt="" />
                                        </div>
                                        <div className="location_story2 location_s2_location01 pos">
                                            <img src="assets/images/story_scene2_location01.png" alt="" />
                                        </div>

                                        <div className="location_story2 location_s2_location02 pos">
                                            <img src="assets/images/story_scene2_location02.png" alt="" />
                                        </div>

                                        <div className="location_story2 location_s2_location03 pos">
                                            <img src="assets/images/story_scene2_location03.png" alt="" />
                                        </div>

                                        <div className="location_story2 location_s2_location04 pos">
                                            <img src="assets/images/story_scene2_location04.png" alt="" />
                                        </div>

                                        <div className="location_story2 location_s2_location05 pos">
                                            <img src="assets/images/story_scene2_location05.png" alt="" />
                                        </div>

                                        <div className="location_story2 location_s2_location06 pos">
                                            <img src="assets/images/story_scene2_location06.png" alt="" />
                                        </div>

                                        <div className="location_story2 location_s2_location07 pos">
                                            <img src="assets/images/story_scene2_location07.png" alt="" />
                                        </div>

                                        <div className="location_story2 location_s2_location08 pos">
                                            <img src="assets/images/story_scene2_location08.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item item_txt scene3">
                                <div className="txt">
                                    <p>คงจะดีถ้า<span className="txtbold">สามารถเช่าพื้น</span><br /><span
                                            className="txtbold">ที่เหล่านี้จัดเพื่อกิจกรรมพิเศษ</span></p>
                                    <p>อาจจะเป็น งานปาร์ตี้สังสรรค์<br />กับเพื่อนหรือครอบครัว<br />จัดงานงานสัมมนา
                                        Workshop<br />หรือเช่าสถานที่เพื่อถ่ายภาพ</p>
                                </div>
                                <div className="img_mb img_mb_scene3">
                                    <div className="img">
                                        <div className="photo">
                                            <div className="story_photo story_photo1">
                                                <img src="assets/images/story_photo1.jpg" alt="" />
                                            </div>
                                            <div className="story_photo story_photo2">
                                                <img src="assets/images/story_photo2.jpg" alt="" />
                                            </div>
                                            <div className="story_photo story_photo3">
                                                <img src="assets/images/story_photo3.jpg" alt="" />
                                            </div>
                                            <div className="story_photo story_photo4">
                                                <img src="assets/images/story_photo4.jpg" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item item_txt scene4">
                                <div className="txt">
                                    <p><span className="txtbold">GU Space
                                            คือแพลทฟอร์ม</span><br /><span
                                            className="txtbold">ที่จะช่วยดำเนินความสะดวก</span><br /><span
                                            className="txtbold">ให้คุณสามารถเช่าพื้นที่</span><br /><span
                                            className="txtbold">ได้ง่ายๆ
                                            เพียงจองผ่านเว็บไซต์</span></p>
                                    <p>เรามีทีมงานที่พร้อมดูแล<br />เพื่อคุณจะได้จัดกิจกรรม<br />ที่ทุกคนจะประทับใจ<br />แถมยังเป็นการช่วย<br />ขับเคลื่อนชุมชนอีกด้วย
                                    </p>
                                </div>
                                <div className="img_mb img_mb_scene4">
                                    <div className="platform">
                                        <svg width="685" height="540" viewBox="0 0 685 540" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M2 135.826C96.6387 64.4946 472.172 -90.142 572.872 80.0027C701.546 297.412 -55.0837 410.007 14.0176 506.305C69.2986 583.343 483.706 497.388 684 444.781"
                                                stroke="#FBFF25" strokeWidth="5" />
                                        </svg>
                                        <div className="ipad"><img src="assets/images/story_ipad.png" alt="" /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col sticky">

                            <div className="dot_scene">
                                <span id="btn_scene1" className="active"></span>
                                <span id="btn_scene2"></span>
                                <span id="btn_scene3"></span>
                                <span id="btn_scene4"></span>
                            </div>
                            <div className="item">
                                <div className="img">
                                    <div className="city">
                                        <img src="assets/images/story_city.svg" alt="" />
                                    </div>
                                    <div className="location_story1 location_s1_location01 pos">
                                        <img src="assets/images/story_scene1_location01.png" alt="" />
                                    </div>
                                    <div className="location_story1 location_s1_location02 pos">
                                        <img src="assets/images/story_scene1_location02.png" alt="" />
                                    </div>
                                    <div className="location_story1 location_s1_location03 pos">
                                        <img src="assets/images/story_scene1_location03.png" alt="" />
                                    </div>
                                    <div className="location_story1 location_s1_location04 pos">
                                        <img src="assets/images/story_scene1_location04.png" alt="" />
                                    </div>

                                    <div className="location_story2 location_s2_location01 pos">
                                        <img src="assets/images/story_scene2_location01.png" alt="" />
                                    </div>

                                    <div className="location_story2 location_s2_location02 pos">
                                        <img src="assets/images/story_scene2_location02.png" alt="" />
                                    </div>

                                    <div className="location_story2 location_s2_location03 pos">
                                        <img src="assets/images/story_scene2_location03.png" alt="" />
                                    </div>

                                    <div className="location_story2 location_s2_location04 pos">
                                        <img src="assets/images/story_scene2_location04.png" alt="" />
                                    </div>

                                    <div className="location_story2 location_s2_location05 pos">
                                        <img src="assets/images/story_scene2_location05.png" alt="" />
                                    </div>

                                    <div className="location_story2 location_s2_location06 pos">
                                        <img src="assets/images/story_scene2_location06.png" alt="" />
                                    </div>

                                    <div className="location_story2 location_s2_location07 pos">
                                        <img src="assets/images/story_scene2_location07.png" alt="" />
                                    </div>

                                    <div className="location_story2 location_s2_location08 pos">
                                        <img src="assets/images/story_scene2_location08.png" alt="" />
                                    </div>

                                    <div className="photo">
                                        <div className="story_photo story_photo1">
                                            <img src="assets/images/story_photo1.jpg" alt="" />
                                        </div>
                                        <div className="story_photo story_photo2">
                                            <img src="assets/images/story_photo2.jpg" alt="" />
                                        </div>
                                        <div className="story_photo story_photo3">
                                            <img src="assets/images/story_photo3.jpg" alt="" />
                                        </div>
                                        <div className="story_photo story_photo4">
                                            <img src="assets/images/story_photo4.jpg" alt="" />
                                        </div>
                                    </div>

                                    <div className="platform"> 
                                        <div className="ipad">  
                                        <svg  style={{zIndex:'-1'}} width="685" height="540" viewBox="0 0 685 540" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M2 135.826C96.6387 64.4946 472.172 -90.142 572.872 80.0027C701.546 297.412 -55.0837 410.007 14.0176 506.305C69.2986 583.343 483.706 497.388 684 444.781"
                                                stroke="#FBFF25" strokeWidth="5" />
                                        </svg>
                                            <img src="assets/images/story_ipad.png" alt="" />
                                            </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
  )
}
