import React, { useEffect } from 'react'
import { Container } from './component.style'
import Content from '../../../components/booking/content'
import useFunction from './function'
import gsap from 'gsap'

export default function Index({
  detailData,
  masterData,
  onChangePage,
  setResultStructure,
  freeSlots,
  setFreeSlots,
  onSelectDate,
  onSelectTimeRange,
  onChangeExpectArriavalTime,
  sumOrderPrice,
  sumOrderHours,
  sumOrderProcessPrice,
  findDuplicateSelected,
}) {
  const { viaContract, onClickBooking } = useFunction()

  useEffect(() => {
    gsap.to('section', {
      duration: 2,
      opacity: 1,
      delay: 1,
      ease: 'power3.out',
    })
  }, [])

  return (
    <Container>
      <Content
        findDuplicateSelected={findDuplicateSelected}
        onChangeExpectArriavalTime={onChangeExpectArriavalTime}
        className="animate__animated animate__fadeIn"
        sumOrderHours={sumOrderHours}
        sumOrderPrice={sumOrderPrice}
        sumOrderProcessPrice={sumOrderProcessPrice}
        masterData={masterData}
        onSelectDate={onSelectDate}
        onSelectTimeRange={onSelectTimeRange}
        freeSlots={freeSlots}
        setFreeSlots={setFreeSlots}
        onClickBooking={onClickBooking}
        viaContract={viaContract}
        detailData={detailData}
        onChangePage={onChangePage}
        setResultStructure={setResultStructure}
      />
    </Container>
  )
}
