import React, { useState } from "react";
import startList from "./utils/list";
import defaultImage from "../../../assets/images/default-image.jpg";
import history from "utils/history";
import "./style.css";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Empty } from "antd";
import EmptyIndex from "components/global/empty";
export default function ListViewComponent({ onHover, onMouseLeave, spaces = [], suggestions, suggestionsData }) {
  const [hovered, setHovered] = useState(false);
  const selectDate = history.location.search.split("selected_date=");

  const handleHover = (item) => {
    setHovered(true);
    onHover(item);
  };
  const handleMouseLeave = () => {
    setHovered(false);
    onMouseLeave();
  };

  const isImgUrl = (url) => {
    const img = new Image();
    img.src = url;
    return new Promise((resolve) => {
      img.onerror = () => resolve(false);
      img.onload = () => resolve(true);
    });
  };
  const getSuggestionText = (text) => {
    if (text === "limit") {
      return "Recommended Location";
    } else {
      if (text === "location") {
        return "Location : ";
      } else if (text === "event") {
        return "Event : ";
      } else if (text === "max_people") {
        return "max";
      } else if (text === "selected_date") {
        return "Date : ";
      } else {
        return "Recommended Location";
      }
    }
  };

  React.useEffect(() => {
    startList();
    SwiperCore.use([Navigation]);
    SwiperCore.use([Pagination]);
  }, []);

  return (
    <div className="col">
      <div className={`content-items ${spaces && spaces.length > 0 ? "" : "empty-space-flex"}`}>
        <div className="content-space-all">
          <p className="result_space">
            We found <span className="txtbold txtblack">{spaces && spaces.length} spaces </span> that might interest you{" "}
          </p>

          {spaces && spaces.length > 0 ? (
            <div className="grid location">
              {" "}
              {spaces.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="item-space"
                    onMouseOver={() => {
                      handleHover(item);
                    }}
                    onMouseLeave={() => {
                      handleMouseLeave(item);
                    }}
                  >
                    <div href={`${item.linkURL}`} className="img">
                      <div className="swiper slide-space-thumb">
                        <div
                          className="swiper-wrapper cursor-pointer"
                          onClick={() => {
                            window.location.href = `/spaces/${item.id}${
                              selectDate.length > 1 ? `?selected_date=${selectDate[1]}` : ""
                            }`;
                          }}
                        >
                          {item.images_display ? (
                            item.images_display.map((image) => {
                              return (
                                <div className="swiper-slide cursor-pointer">
                                  <img src={`${image ? image : defaultImage}`} alt="space" loading="lazy" />
                                </div>
                              );
                            })
                          ) : (
                            <div className="swiper-slide">
                              <img src={`${defaultImage}`} alt="space" />
                            </div>
                          )}
                        </div>
                        <div name={`${index}`} className="swiper-button-prev"></div>
                        <div name={`${index}`} className="swiper-button-next"></div>
                        <div className="swiper-pagination"></div>
                      </div>
                    </div>
                    <a
                      href={`/spaces/${item.id}${selectDate.length > 1 ? `?selected_date=${selectDate[1]}` : ""}`}
                      className="txt cursor-pointer text-black hover:text-black"
                    >
                      <div className="title">
                        <h2>{item.space_detail_options}</h2>
                        <span>{/* <b>{item.name}</b>   */}</span>
                        <p>{item.location} </p>
                      </div>
                      <div className="sub">
                        <div className="price">
                          <h6>
                            Starting from {item.min_price ? new Intl.NumberFormat().format(item.min_price) : 0} THB/hr.
                          </h6>
                        </div>
                        <div className="people">
                          <h6 style={{ fontSize: "12px" }}>{item.max_people ? item.max_people : "-"} people</h6>
                        </div>
                      </div>
                    </a>
                  </div>
                );
              })}
            </div>
          ) : (
            <div>
              <div className="mt-4">
                <center>
                  <h2 className="text-2xl font-semibold font-noto">Sorry :(</h2>
                  <h3 className="text-2xl font-semibold font-noto">
                    This ideal space you seek is not yet available on
                  </h3>
                  <h3 className="text-2xl font-semibold font-noto">our website, but we will keep looking.</h3>
                </center>
              </div>
            </div>
          )}
          {suggestionsData && spaces.length == 0 && (
            <div style={{ marginTop: "90px" }}>
              <h2 style={{ fontSize: "18px" }}>
                <b>Suggestion you might be interested in other space in </b>
                <span style={{ textTransform: "capitalize" }}>
                  <b>{getSuggestionText(suggestionsData.filter)} </b>
                </span>
                <span style={{ textTransform: "capitalize" }}>
                  {suggestionsData.filter !== "limit"
                    ? `${suggestionsData.key} ${suggestionsData.filter === "max_people" ? " people" : ""}`
                    : ""}
                </span>
              </h2>
            </div>
          )}
          {suggestions && suggestions.length > 0 && spaces.length == 0 ? (
            <div className="grid location mt-6">
              {suggestions.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="item-space"
                    onMouseOver={() => {
                      handleHover(item);
                    }}
                    onMouseLeave={() => {
                      handleMouseLeave(item);
                    }}
                  >
                    <div
                      href={`${item.linkURL}`}
                      className="img cursor-pointer"
                      onClick={() => {
                        window.location.href = `/spaces/${item.id}${
                          selectDate.length > 1 ? `?selected_date=${selectDate[1]}` : ""
                        }`;
                      }}
                    >
                      <div className="swiper slide-space-thumb">
                        <div className="swiper-wrapper">
                          {item.images_display ? (
                            item.images_display.map((image) => {
                              return (
                                <div className="swiper-slide">
                                  <img src={`${image ? image : defaultImage}`} alt="space" />
                                </div>
                              );
                            })
                          ) : (
                            <div className="swiper-slide">
                              <img src={`${defaultImage}`} alt="space" />
                            </div>
                          )}
                        </div>
                        <div name={`${index}`} className="swiper-button-prev"></div>
                        <div name={`${index}`} className="swiper-button-next"></div>
                        <div className="swiper-pagination"></div>
                      </div>
                    </div>
                    <a
                      href={`/spaces/${item.id}${selectDate.length > 1 ? `?selected_date=${selectDate[1]}` : ""}`}
                      className="txt cursor-pointer text-black hover:text-black"
                    >
                      <div className="title">
                        <h2>{item.space_detail_options}</h2>
                        {/* <span>
                          <b>{item.name}</b> ({item.space_type})
                        </span> */}
                        <p>{item.location} </p>
                      </div>
                      <div className="sub">
                        <div className="price">
                          <h6>Starting from {new Intl.NumberFormat().format(item.min_price)} THB/hr.</h6>
                        </div>
                        <div className="people" style={{}}>
                          <h6 style={{ fontSize: "12px" }}>{item.max_people ? item.max_people : "-"} people</h6>
                        </div>
                      </div>
                    </a>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="empty-container"></div>
          )}
        </div>
      </div>
    </div>
  );
}
