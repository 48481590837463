import $ from 'jquery'
import 'jquery.cycle2'
import Swiper from 'swiper'

const startList = () => {


  $(function () {
    let swiperLooking = new Swiper(
      '.content-looking .items',
      {
        slidesPerView: 'auto',
        spaceBetween: 10,
        observer: true,
        observeParents: true,
        navigation: {
          nextEl: '.content-looking .swiper-button-next',
          prevEl: '.content-looking .swiper-button-prev',
        },
      },
      []
    )

    setTimeout(function () {
      let swiperSingleThumb = new Swiper(
        '.slide-single-space-thumb',
        {
          slidesPerView: 1,
          effect: 'cards',
          cardsEffect: {
            rotate: false,
            slideShadows: false,
          },
          speed: 400,
          observer: true,
          observeParents: true,
          // loop: true,
          navigation: {
            nextEl: '.slide-single-space-thumb .swiper-button-next',
            prevEl: '.slide-single-space-thumb .swiper-button-prev',
          },
        },
        []
      )
    }, 300)

    $('.content-looking .item').click(function () {
      $(this).toggleClass('active')
    })

    setTimeout(() => {
      let swiperThumb = new Swiper(
        '.slide-space-thumb',
        {
          slidesPerView: 1,
          effect: 'fade',
          observer: true,
          observeParents: true,
          loop: true,
          navigation: {
            nextEl: '.slide-space-thumb .swiper-button-next',
            prevEl: '.slide-space-thumb .swiper-button-prev',
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
        },
        []
      )

      $('.slide-space-thumb .swiper-button-next').click(function () {
       try {
        let index = $(this).attr('name')
        swiperThumb[index].slideNext()
       } catch (error) {
        
       }
      })
      $('.slide-space-thumb .swiper-button-prev').click(function () {
        let index = $(this).attr('name')
        swiperThumb[index].slidePrev()
      })

      $('.toggle_map input').prop('checked', true)
      $('.toggle_map').click(function () {
        if (!$('.toggle_map input').prop('checked')) {
          $('.grid-space-all').addClass('hide_map')
        } else {
          $('.grid-space-all').removeClass('hide_map')
        }
      })
    }, 1000)
  })
}

export default startList
