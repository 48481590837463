import React, { useEffect } from 'react'
import 'jquery.cycle2'
import useFunction from './function'

import 'assets/css/main_homepage.css'
import 'assets/css/responsive_homepage.css'

import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

export default function WindowComponent() {
  const { windows } = useFunction()
  let iwin = 0
  //Animation Window Open
  useEffect(() => {
    if (windows.length > 0) {
      gsap.registerPlugin(ScrollTrigger)
      ScrollTrigger.refresh()
    }
  }, [windows, gsap, iwin, ScrollTrigger])

  console.log('window', window)

  return (
    windows && (
      <section className="visit">
        <div className="txthead text-center">
          <h2>
            peek through
            <br />
            the windows
          </h2>
          <p>แอบดูผ่านหน้าต่าง</p>
        </div>

        <div className="swiper" id="slide_visit">
          <div className="swiper-wrapper">
            {windows?.map((item, index) => {
              if (iwin < 3) {
                iwin = iwin + 1
              } else {
                iwin = iwin - 2
              }
              return (
                <div className="swiper-slide" key={index + 1}>
                  <div className={`window${iwin}`}>
                    <div className={`window${iwin}_main`}>
                      <div className={`window${iwin}_left`}>
                        <img src={`/assets/images/window/window${iwin}-left.svg`} alt="" />
                      </div>
                      <div
                        onClick={() => {
                          window.location.href = `/spaces/${item.id}`
                        }}
                        className={`cursor-pointer window${iwin}_frame`}
                      >
                        <img src={`/assets/images/window/window${iwin}-main.svg`} alt="" />
                        <img src={item.images_display ? item.images_display[0] : `/assets/images/window${iwin}_img.jpg`} className="window_img_main" alt="" />
                      </div>
                      <div className={`window${iwin}_right`}>
                        <img src={`/assets/images/window/window${iwin}-right.svg`} alt="" />
                      </div>
                    </div>
                  </div>

                  <div
                    className="txt text-center cursor-pointer"
                    onClick={() => {
                      window.location.href = `/spaces/${item.id}`
                    }}
                  >
                    <h2 className="font-noto font-bold">{item.space_detail_options}</h2>
                    {/*<p>{item.name}</p>*/}
                    <p style={{ marginTop: '0px' }}>{item.location}</p>
                    <div className="sub_detail">
                      <div className="item">
                        <img src="/assets/images/i_baht.svg" alt="" />
                        <span>Starting from {item.min_price ? new Intl.NumberFormat().format(item.min_price) : 0} THB/hr.</span>
                      </div>
                      <div className="item">
                        <img src="/assets/images/i_search_people.svg" alt="" />
                        <span>{item.max_people} people</span>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}

            {/* <div className="swiper-slide">
            <div className="window1"  style={{padding:'0px'}} >
              <div className="window1_main">
                <div className="window1_left">
                  <img src="/assets/images/window/window1-left.svg" alt="" />
                </div>
                <div className="window1_frame">
                  <img src="/assets/images/window/window1-main.svg" alt="" />
                  <img src="https://web-assets-uat.guspace.com/1024/spaces/ncb4/IMG_7715.jpeg" className="window_img_main" alt="" />
                </div>
                <div className="window1_right">
                  <img src="/assets/images/window/window1-right.svg" alt="" />
                </div>
              </div>
            </div>
            <div className="txt text-center">
              <h2 className="font-noto font-bold">LUK HOSTEL</h2>
              <p>เยาวราช</p>
              <div className="sub_detail">
                <div className="item">
                  <img src="/assets/images/i_baht.svg" alt="" />
                  <span>เริ่มต้นที่ 1,500 บาท/ชม.</span>
                </div>
                <div className="item">
                  <img src="/assets/images/i_search_people.svg" alt="" />
                  <span>20 คน</span>
                </div>
              </div>
            </div>
          </div>

          <div className="swiper-slide">
            <div className="window2"  style={{padding:'0px'}} >
              <div className="window2_main">
                <div className="window2_left">
                  <img src="/assets/images/window/window2-left.svg" alt="" />
                </div>
                <div className="window2_frame">
                  <img src="/assets/images/window/window2-main.svg" alt="" />
                  <img src="https://web-assets-uat.guspace.com/1024/spaces/ncb4/IMG_7715.jpeg" className="window_img_main" alt="" />
                </div>
                <div className="window2_right">
                  <img src="/assets/images/window/window2-right.svg" alt="" />
                </div>
              </div>
            </div>
            <div className="txt text-center">
              <h2 className="font-noto font-bold">LUK HOSTEL</h2>
              <p>เยาวราช</p>
              <div className="sub_detail">
                <div className="item">
                  <img src="/assets/images/i_baht.svg" alt="" />
                  <span>เริ่มต้นที่ 1,500 บาท/ชม.</span>
                </div>
                <div className="item">
                  <img src="/assets/images/i_search_people.svg" alt="" />
                  <span>20 คน</span>
                </div>
              </div>
            </div>
          </div>

          <div className="swiper-slide">
            <div className="window3"  style={{padding:'0px'}}>
              <div className="window3_main">
                <div className="window3_left">
                  <img src="/assets/images/window/window3-left.svg" alt="" />
                </div>
                <div className="window3_frame">
                  <img src="/assets/images/window/window3-main.svg" alt="" />
                  <img src="https://web-assets-uat.guspace.com/1024/spaces/ncb4/IMG_7715.jpeg" className="window_img_main" alt="" />
                </div>
                <div className="window3_right">
                  <img src="/assets/images/window/window3-right.svg" alt="" />
                </div>
              </div>
            </div>
            <div className="txt text-center">
              <h2 className="font-noto font-bold">LUK HOSTEL</h2>
              <p>เยาวราช</p>
              <div className="sub_detail">
                <div className="item">
                  <img src="/assets/images/i_baht.svg" alt="" />
                  <span>เริ่มต้นที่ 1,500 บาท/ชม.</span>
                </div>
                <div className="item">
                  <img src="/assets/images/i_search_people.svg" alt="" />
                  <span>20 คน</span>
                </div>
              </div>
            </div>
          </div> */}
          </div>

          <div id="swiperprev" className="swiper-button-prev" style={{ backgroundImage: 'url(assets/images/arr_prev2.svg)' }}></div>
          <div id="swipernext" className="swiper-button-next" style={{ backgroundImage: 'url(assets/images/arr_next2.svg)' }}></div>
        </div>
      </section>
    )
  )
}
