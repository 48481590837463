import React from "react";
import startMenu from "components/global/navbar/utils/menu";
import { Modal } from "antd";
import Socials from "components/global/socials";

import FilterComponent from "../filter";
import "./style.css";
export default function NavbarComponent() {
  const [isScrolled, setIsScrolled] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [openSearch, setOpenSearch] = React.useState(false);

  const listImages = [
    "assets/images/picture_menu_random/1.jpg",
    "assets/images/picture_menu_random/2.jpg",
    "assets/images/picture_menu_random/3.jpg",
    "assets/images/picture_menu_random/4.jpg",
    "assets/images/picture_menu_random/5.jpg",
    "assets/images/picture_menu_random/6.jpg",
    "assets/images/picture_menu_random/7.jpg",
    "assets/images/picture_menu_random/8.jpg",
    "assets/images/picture_menu_random/9.jpg",
    "assets/images/picture_menu_random/10.jpg",
  ];
  React.useEffect(() => {
    startMenu();
    function handleScroll() {
      if (window.pageYOffset > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <header className={`header_home ${isScrolled ? "navbar-scroll" : ""}`}>
      <div className="inner">
        <div className="col">
          <div className="logo_main showmb">
            <a href="/">
              <img src="/assets/images/logo-gu-space.svg" alt="" />
            </a>
          </div>
          <div
            onClick={(e) => {
              setOpenMenu(true);
            }}
            className="btn_hamburger showpc"
          >
            <img src="/assets/images/btn_hamburger.svg" alt="" />
          </div>
        </div>
        <div className="col">
          <div className="logo_main showpc">
            <a href="/">
              <img src="/assets/images/logo-gu-space.svg" alt="" />
            </a>
          </div>
        </div>
        <div className="col">
          <div
            onClick={(e) => {
              setOpenSearch(true);
            }}
            className="btn_search"
          >
            <img src="/assets/images/btn_search.svg" alt="" />
          </div>
          <div
            onClick={(e) => {
              setOpenMenu(true);
            }}
            className="btn_hamburger showmb"
          >
            <img src="/assets/images/btn_hamburger.svg" alt="" />
          </div>
        </div>
      </div>
      {openMenu && (
        <div className="expand active">
          <div className="txt">
            <div className="txt_inner">
              <ul>
                <li>
                  <a href="/" id="menu_home">
                    Home
                  </a>
                </li>
                <li>
                  <a href="/about" id="menu_all_about">
                    About
                  </a>
                </li>
                <li>
                  <a href="/gallery" id="menu_gallery">
                    Gallery
                  </a>
                </li>
                <li>
                  <a href="/spaces" id="menu_all_spaces">
                    ALL Spaces
                  </a>
                </li>
                <li>
                  <a href="/contact" id="menu_contact">
                    CONTACT
                  </a>
                </li>
                {/* <li>
                  <a href="#!" id="menu_about">
                    About
                  </a>
                </li>
                <li>
                  <a href="#!" id="menu_gallery">
                    Gallery
                  </a>
                </li>
                <li>
                  <a href="#!" id="menu_contact">
                    Contact
                  </a>
                </li> */}
              </ul>

              <Socials />
              <div className="copyright">
                <p>
                  &copy; 2023 GU SPACE. Registered in Thailand. Company Registration Number 13324675. VAT Number
                  3795673330. Address: 18 Golden Road, Bangkok Thailand, 10900 Privacy Policy
                </p>
              </div>
            </div>
          </div>

          <div className="img">
            <img src={listImages[Math.floor(Math.random() * 10)]} alt="" />
          </div>

          <button
            onClick={(e) => {
              setOpenMenu(false);
            }}
            className="btn_close_menu"
          >
            <img src="assets/images/btn_close_menu.svg" alt="" />
          </button>
        </div>
      )}
      {openSearch && (
        <Modal
          className="custom-modal wrap_modal"
          centered
          open={openSearch}
          onOk={() => setOpenSearch(false)}
          closable={false}
          okButtonProps={{ style: { display: "none" } }}
          cancelButtonProps={{ style: { display: "none" } }}
          width={600}
        >
          <div className="modal_content relative bg-ci-guspace shadow">
            <div className="modal_header flex items-center justify-between">
              <h3 className="font-bold font-noto">SEARCH</h3>
              <button
                onClick={(e) => {
                  setOpenSearch(false);
                }}
                type="button"
                className="inline-flex items-center"
                data-modal-hide="searchModal"
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2 2L22.4043 22.4044" stroke="black" stroke-width="4"></path>
                  <path d="M22.4043 2L1.99991 22.4043" stroke="black" stroke-width="4"></path>
                </svg>
              </button>
            </div>
            <hr />
            <div className="modal_body" style={{ height: "auto", padding: "40px 24px" }}>
              <FilterComponent />
            </div>
          </div>
        </Modal>
      )}
    </header>
  );
}
