import React from 'react'
import NavbarSpaceComponent from 'components/global/navbarspace'
import FooterComponent from 'components/global/footer'
import JoiningComponent from 'components/spaces/joining'
import ListViewComponent from 'components/spaces/listview'
import FilterFEComponet from 'components/spaces/listview/filter'
import MapComponent from 'components/spaces/map'
import useFunction from './function'
import useFunctionSpaces from 'components/spaces/listview/function'

export default function SpacesContainer() {
  const {
    location,
    showMap,
    spacesData,
    setShowMap,
    handleHover,
    handleLeave,
    suggestions,
    suggestionsData,
    spaceType,
    center,
    markRender,
    handleSpaceFilter,
    handleAdvanceFilter,
  } = useFunction()
  const { spaceTypeData, amenities, features } = useFunctionSpaces()

  React.useEffect(() => {}, [])

  return (
    <div className="wrapper" >
      <NavbarSpaceComponent />
      <main>
        <section className="grid-space-all">
          <div className="col">
            <div className="content-space-all">     
              <FilterFEComponet
                spaceType={spaceTypeData}
                spaceTypeUse={spaceType}
                amenities={amenities}
                features={features}
                handleSpaceFilter={handleSpaceFilter}
                handleAdvanceFilter={handleAdvanceFilter}
              />

              {spacesData.space && <ListViewComponent
                spaces={spacesData.space}
                onHover={handleHover}
                onMouseLeave={handleLeave}
                suggestions={suggestions.space}
                suggestionsData={suggestionsData}
                spaceTypeData={spaceType}
              />}
              
            </div>
          </div>
          
          {showMap ? 
            <MapComponent className="animate__animated animate__fadeIn animate__delay-2" spaces={spacesData.space} location={location} center={center} markRender={markRender} ></MapComponent> :
          <div>
            <span className="loader"></span>
          </div>}
          <div className="toggle_map">
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                onChange={(e) => {
                  setShowMap(e.target.checked)
                }}
                value={showMap}
                type="checkbox"
                className="sr-only peer"
              />
              <div className="switch w-11 h-6 bg-black rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              <span className="ml-2 mr-1 font-noto text-base font-bold text-black">Map</span>
            </label>
          </div>
        </section>
        <JoiningComponent />
      </main>
      <FooterComponent />
    </div>
  )
}
