import React from 'react'
import { windows_home } from 'utils/services/api/schemas'
import CommonServives from 'utils/services/api/common' 
export default function WindowsFunction() {
  const [windows, setWindows] = React.useState([])
  const { getList } = CommonServives()

  React.useEffect(() => {
    GetWindows()
  }, [])

  function GetWindows() {
    getList(`${windows_home}`, {}, true)
      .then((response) => {
        //getSuggestions(response.data)
        if (response.data) {
          let windows = response.data 
          let windowLength = windows.space.length > 0 && windows.space <= 5 ? windows.space.length : 5
          windows.space = windows.space.slice(0, windowLength)
          setWindows(windows.space)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return { windows }
}
