import React, { useEffect, useContext } from 'react'
import NavbarComponentHome from 'components/global/navbar'
import FooterComponent from 'components/global/footer'
import HeroBannerComponent from 'components/home/herobanner'
import InformationComponent from 'components/home/information'
import WindowComponent from 'components/home/window'
import SpaceMomentComponent from 'components/home/spacemoment'
import StoryComponent from 'components/home/story'
import PlanningComponent from 'components/home/planning'
import JoinComponent from 'components/home/join'
import startAnimation from '../../utils/animate'
import useFunction from './function'
import gsap from 'gsap'
import { Loading } from 'utils/services/store'
import 'assets/css/main_homepage.css'
import 'assets/css/responsive_homepage.css'
import { set } from 'react-ga'

export default function HomeContainer() {
  const { masterData } = useFunction()
  const { setIsLoading } = useContext(Loading)
  const [response, setResponse] = React.useState(false)
  useEffect(() => {
    document.body.style.overflow = "hidden"; 
    setIsLoading(true) 
    
    gsap.to('body', {
      duration: 2,
      opacity: 1,
      delay: 1,
      ease: 'power3.out',
    })
    setTimeout(() => { 
      document.body.style.overflow = "scroll"
      startAnimation() 
      setIsLoading(false)
    } , 3000)

  }, [])


  return (
    <>
    <div className="wrapper">
      <NavbarComponentHome reqDetail={false} disableBg={true} />

      <HeroBannerComponent masterData={masterData} />
      
      <main>
        <InformationComponent />
        <WindowComponent />
        <SpaceMomentComponent />
        <StoryComponent />
        <PlanningComponent />
        <JoinComponent />
      </main>
      <FooterComponent />
    </div>
    </>
  )
}
