import HttpClient from '../httpClient'

export default function CommonServives() {
  const { httpClient } = HttpClient()
  let device = window.innerWidth <= 640 ? 'mobile' : window.innerWidth <= 1024 ? 'tablet' : 'desktop'

  function create(schemas, params) {
    return httpClient
      .post(schemas, params)
      .then((response) => {
        return response.data || response.response.data
      })
      .catch((err) => {
        return err.data
      })
  }

  function update(schemas, id, params) {
    return httpClient
      .put(id ? `${schemas}/${id}` : `${schemas}`, params)
      .then((response) => {
        return response.data || response.response.data
      })
      .catch((error) => {
        return error.data
      })
  }

  function getById(schemas, id, params = {}, byDevice = false) {
    let api = schemas.split('?')
    let devices = api[1] ? `&device=${device}` : `?device=${device}`
    return httpClient
      .get(`${schemas}/${id}${byDevice ? devices : ''}`, { params })
      .then((response) => {
        return response.data || response.response.data
      })
      .catch((error) => {
        return error.data
      })
  }

  function getList(schemas, params, byDevice = false) {
    let api = schemas.split('?')
    let devices = api[1] ? `&device=${device}` : `?device=${device}`

    return httpClient
      .get(`${schemas}${byDevice ? devices : ''}`, { params })
      .then((response) => {
        return response.data || response.response.data
      })
      .catch((err) => {
        return err.data
      })
  }

  return { create, update, getById, getList }
}
