import React, { useEffect } from 'react'
import NavbarComponentHome from 'components/global/navbar'
import FooterComponent from 'components/global/footer'
import startAnimation from 'utils/animate'
import gsap from 'gsap'
export default function ErrorContainer() {
  useEffect(() => {
    startAnimation()
    gsap.to('body', {
      duration: 2,
      opacity: 1,
      delay: 1,
      ease: 'power3.out',
    })
  }, [])

  return (
    <>
      <div className="wrapper">
        <NavbarComponentHome reqDetail={false} disableBg={true} />
        <main>
          <div className=" p-4">
            <div className="flex flex-col w-full h-screen justify-center items-center">
              <h2 className="font text-4xl font-semibold">Page not found</h2>
              <center>
                <span className="font font-semibold">Can't find the information you are looking for? Please go back to the home page.</span>
              </center>
              <a href={`/`} type="button" className="btn-yellow   text-center justify-center mt-6">
                Back to home
              </a>
            </div>
          </div>
        </main>
        <FooterComponent />
      </div>
    </>
  )
}
