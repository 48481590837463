import React from "react";
import history from "utils/history";

export default function NavbarFunction() {
  function getParams() {
    const searchParams = new URLSearchParams(history.location.search);
    const params = {};
    for (const [key, value] of searchParams.entries()) {
      params[key] = value;

      if (key === "event") {
        params[key] = params[key].replaceAll("-", "&");
        params[key] = params[key].replaceAll("_", "|");
      }
    }
    return params;
  }
  return { getParams };
}
