import React, { useState, useEffect } from "react";
import NavbarSpaceComponent from "components/global/navbarspace";
import AccordionItem from "components/global/accordionItem";
import FooterColComponent from "components/global/footerCol";
import startAnimation from "utils/animate";
import AOS from "aos";

// image import
import aboutMoImage from "assets/images/about-mo.png";
import about11Image from "assets/images/about11.jpg";
import about12Image from "assets/images/about12.jpg";
import about13Image from "assets/images/about13.jpg";
import about14Image from "assets/images/about14.jpg";
import about21Image from "assets/images/about21.jpg";
import about22Image from "assets/images/about22.jpg";
import about23Image from "assets/images/about23.jpg";
import about24Image from "assets/images/about24.jpg";
import waterMark1Image from "assets/images/whatmake01.svg";
import waterMark2Image from "assets/images/whatmake02.svg";
import waterMark3Image from "assets/images/whatmake03.svg";
import waterMark4Image from "assets/images/whatmake04.svg";

//style and function from them self
import "./style.css";
import "aos/dist/aos.css";
import useFunction from "./function";

export default function SpacesContainer() {
  const {} = useFunction();
  const [expanSelectIndex, setExpanSelectIndex] = useState(0);
  // const { spaceTypeData, amenities, features } = useFunctionSpaces()
  const faq_list = [
    { 
      "question": "คุณสามารถจองพื้นที่ภายในเว็บไซต์เลยได้มั้ย",
      "result": "ผู้เข้าร่วมกิจกรรมสามารถเลือกสถานที่ กิจกรรมที่ต้องการ ตรวจสอบช่วงเวลาว่างของสถานที่และกดจองพื้นที่ผ่านหน้าเว็บไซต์ GU Space ได้เลย"
    },
    { 
      "question": "จำนวนคนเข้าร่วมสามารถปรับเปลี่ยนได้มั้ย",
      "result": "จำนวนของผู้เข้าร่วมกิจกรรมสามารถปรับเปลี่ยนได้ โดยสามารถติดต่อผ่านทีมงานของ GU Space ทั้งนี้ผู้เข้าร่วมสามารถตรวจสอบจำนวนผู้เข้าร่วมสูงสุดของแต่ละสถานที่ที่กำหนดอยู่บนหน้าเว็ปไซต์"
    },
    { 
      "question": "หากต้องการขยายเวลาเช่าพื้นที่สามารถทำได้มั้ย",
      "result": "ผู้เข้าร่วมกิจกรรมสามารถเลือกช่วงเวลาที่จะขยายเวลาเช่าพื้นที่ได้จากทางเว็ปไซต์ หรือสามารถสอบถามข้อมูลเพิ่มเติมได้ผ่านทางทีมงาน GU Space"
    },{ 
      "question": "ในกิจกรรมมีเครื่องดื่มแอลฮอล์ได้มั้ย",
      "result": "ผู้เข้าร่วมกิจกรรมสามารถตรวจสอบพื้นที่ที่อนุญาตให้มีเครื่องดื่มแอลกอฮอล์ได้ผ่านทางหน้าเว็ปไซต์"
    },{ 
      "question": "หากต้องการยกเลิกการจอง สามารถทำได้มั้ย",
      "result": "ผู้เข้าร่วมกิจกรรมสามารถยกเลิกการจองได้ระหว่าง 7 วันถึง 24 ชั่วโมงก่อนเวลาเริ่มกิจกรรม และรับเงินมัดจำคืน 50% ของราคาจอง การแจ้งยกเลิกการจองที่น้อยกว่า 24 ชั่วโมง ก่อนเวลาเริ่มกิจกรรมจะไม่สามารถขอเงินมัดจำคืนได้"
    }
  ]
  useEffect(() => {
    startAnimation();
    AOS.init();
  }, []);

  return (
    <div className="wrapper about-page">
      <NavbarSpaceComponent reqDetail={false} />
      <main>
        {/* <section className="grid-space-all"></section> */}
        <section className="section1">
          <h1>GU SPACE</h1>
          <h2>
            Creating space <br />
            for communities <br />
            to thrive
          </h2>
        </section>

        <section className="section2">
          <p>
            พื้นที่นับร้อยที่คนมองข้าม แต่เรามองเห็นคุณค่าภายในนั้น
            เราเชื่อว่าคนทำให้พื้นที่มีชีวิต และชีวิตก็ทำให้ชุมชนเติบโต
            <br />
            <br />
            GU Space (อ่านว่า จี-ยู-สเปส) คือแพลตฟอร์มที่จะช่วย
            ดำเนินความสะดวกให้คุณสามารถเช่าพื้นที่ในย่านเมืองเก่า
            เพื่อจัดกิจกรรมพิเศษของคุณได้ง่ายๆ เพราะเป้าหมาย
            ของเราคือการให้พื้นที่ได้เติมเต็มทุกความรู้สึกของคุณ
            และคุณเองก็ได้มีส่วนร่วมในการช่วยชุมชนไปด้วยกัน
          </p>
          <img
            className="img02"
            data-aos="fade-up"
            data-aos-duration="1000"
            src={aboutMoImage}
            alt=""
          />
          <div className="img-wrap">
            <img
              data-aos="zoom-in"
              data-aos-duration="800"
              data-aos-offset="200"
              className="img img11"
              src={about11Image}
              alt=""
            />
            <img
              data-aos="zoom-in"
              data-aos-duration="1200"
              className="img img12"
              src={about12Image}
              alt=""
            />
            <img
              data-aos="zoom-in"
              data-aos-duration="600"
              className="img img13"
              src={about13Image}
              alt=""
            />
            <img
              data-aos="zoom-in"
              data-aos-duration="1000"
              className="img img14"
              src={about14Image}
              alt=""
            />
          </div>
        </section>

        <section className="section3">
          <div className="container600">
            <h2>what makes us different</h2>
            <div className="wrap">
              <div className="item">
                <img src={waterMark1Image} alt="" />
                <h3>ชุบชีวิตให้พื้นที่</h3>
                <p>
                  ทีมงานของเราลงพื้นที่สำรวจ พื้นที่น่าสนใจที่ถูกมองข้าม
                  โดยตั้งใจสร้างพื้นที่ให้มีชีวิต
                </p>
              </div>
              <div className="item">
                <img src={waterMark2Image} alt="" />
                <h3>กระจายรายได้สู่ชุมชน</h3>
                <p>
                  เพราะความตั้งใจของเราคือการแบ่งปัน เปลี่ยนพื้นที่ว่าง
                  เป็นมูลค่าให้กับผู้คน
                </p>
              </div>
              <div className="item">
                <img src={waterMark3Image} alt="" />
                <h3>รองรับกิจกรรมที่หลากหลาย</h3>
                <p>
                  พื้นที่ของเรามีความแตกต่าง และมีเอกลักษณ์ พร้อมสำหรับ
                  กิจกรรมที่มีความหลากหลาย
                </p>
              </div>
              <div className="item">
                <img src={waterMark4Image} alt="" />
                <h3>มีทีมงานคอย Support ตลอด</h3>
                <p>
                  เราทำให้ง่ายที่สุดในทุกขั้นตอน คุณไม่ต้องกังวล เรื่องการจอง
                  และการจัดงานอีกต่อไป{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="img-wrap">
            <img
              data-aos="zoom-in"
              data-aos-duration="600"
              className="img img21"
              src={about21Image}
              alt=""
            />
            <img
              data-aos="zoom-in"
              data-aos-duration="1000"
              className="img img22"
              src={about22Image}
              alt=""
            />
            <img
              data-aos="zoom-in"
              data-aos-duration="800"
              className="img img23"
              src={about23Image}
              alt=""
            />
            <img
              data-aos="zoom-in"
              data-aos-duration="1200"
              className="img img24"
              src={about24Image}
              alt=""
            />
          </div>
        </section>

        <section className="section4">
          <div className="container790">
            <h2>FAQ</h2>
            <div className="accordion" data-aos="fade-up" data-aos-delay="100"> 
              {faq_list && faq_list.map((item, index) => {
                return (<AccordionItem
                number={`${(index < 10)?'0':''}${index + 1}`}
                title={item.question}
                index={index+1}
                onClick={() => {
                  if(index+1 > 0){
                    setExpanSelectIndex((prevIndex) =>
                      prevIndex === index+1 && expanSelectIndex > 0 ? 0 : index+1
                    )
                  }else{
                    setExpanSelectIndex((prevIndex) => (prevIndex === 1 ? 0 : 1))
                  } 
                } 
                }
                selectIndex={expanSelectIndex}
                >
                  {item.result}
                 </AccordionItem>)
                
              })} 
             
            </div>
          </div>
        </section>

        <section className="planning">
          <div className="inner">
            <div className="txt">
              <div className="wrap_svg">
                <svg
                  className="line3"
                  width="372"
                  height="76"
                  viewBox="0 0 372 76"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 73.9998C3.89414 51.8304 20.8062 37.0772 40.5196 37.0772C58.2674 37.0772 79.9425 53.5805 82.2576 73.9998C86.0458 52.7054 103.958 17.2327 145.376 17.2327C186.795 17.2327 211.025 52.7054 214.392 73.9998C218.678 52.7054 237.518 2 291.549 2C354.639 2 370 74 370 74"
                    stroke="#FBFF25"
                    stroke-width="4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <img src="assets/images/dot.svg" className="dot" alt="" />
                <svg
                  className="path"
                  width="372"
                  height="76"
                  viewBox="0 0 372 76"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 73.9998C3.89414 51.8304 20.8062 37.0772 40.5196 37.0772C58.2674 37.0772 79.9425 53.5805 82.2576 73.9998C86.0458 52.7054 103.958 17.2327 145.376 17.2327C186.795 17.2327 211.025 52.7054 214.392 73.9998C218.678 52.7054 237.518 2 291.549 2C354.639 2 370 74 370 74"
                    stroke="#FBFF25"
                    stroke-width="4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <h2>start planning the event of a lifetime</h2>
              <div className="text-center">
                <a
                  href="all_space.html"
                  className="btn_booking btn-black text-center items-center justify-center"
                >
                  ค้นหา space ที่เหมาะกับคุณ
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="joining joining_main bg-yellow py-12 md:py-10">
          <div className="grid text-center items-center">
            <div>
              <p>
                หากคุณมีพื้นที่อยากเข้าร่วมกับ GU Space
                <br />
                สามารถส่งข้อมูลหาเราได้ที่นี่
              </p>
            </div>
            <div>
              <img src="assets/images/join.svg" alt="" className="mx-auto" />
              <h2 className="uppercase font-bold font-noto text-3xl mt-3">
                Joining our
                <br />
                neighborhood
              </h2>
            </div>
            <div>
              <a target="_blank"
                href="https://docs.google.com/forms/d/e/1FAIpQLSdc44zPyubKfep2COUNB1vGBL5wH_NdpaQmzpInAq1C8CrtbA/viewform?usp=pp_url"
                className="btn bg-black inline-block text-white rounded-full py-2.5 p-8 font-bold font-ibm text-base md:text-lg hover:underline"
              >
                กรอกแบบฟอร์ม
              </a>
            </div>
          </div>
        </section>
      </main>
      <FooterColComponent />
    </div>
  );
}
