import React from 'react' 
import Socials from 'components/global/socials'
export default function FooterComponent() {
  return (
    <footer>
      <div className="inner">
        <div className="top">
          <div className="logo_footer">
            <img src="/assets/images/logo-gu-space.svg" alt="" aria-label="gu-home" />
          </div>
          <Socials /> 
        </div>
        <div className="copyright">
          <p>
            &copy; 2023 GU SPACE. Registered in Thailand. Company Registration Number 13324675. VAT Number 3795673330. Address: 18 Golden Road,
            Bangkok Thailand, 10900 Privacy Policy
          </p>
        </div>
      </div>
    </footer>
  )
}
