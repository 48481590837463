import React from 'react'
export default function PlanningComponent() {
  return (
    <section className="planning">
      <div className="inner">
        <div className="txt">
          <div className="wrap_svg">
            <svg className="line3" width="372" height="76" viewBox="0 0 372 76" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M2 73.9998C3.89414 51.8304 20.8062 37.0772 40.5196 37.0772C58.2674 37.0772 79.9425 53.5805 82.2576 73.9998C86.0458 52.7054 103.958 17.2327 145.376 17.2327C186.795 17.2327 211.025 52.7054 214.392 73.9998C218.678 52.7054 237.518 2 291.549 2C354.639 2 370 74 370 74"
                stroke="#FBFF25"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <img src="assets/images/dot.svg" className="dot" alt="" />
            <svg className="path" width="372" height="76" viewBox="0 0 372 76" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M2 73.9998C3.89414 51.8304 20.8062 37.0772 40.5196 37.0772C58.2674 37.0772 79.9425 53.5805 82.2576 73.9998C86.0458 52.7054 103.958 17.2327 145.376 17.2327C186.795 17.2327 211.025 52.7054 214.392 73.9998C218.678 52.7054 237.518 2 291.549 2C354.639 2 370 74 370 74"
                stroke="#FBFF25"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <h2>start planning the event of a lifetime</h2>
          <div className="text-center">
            <a href="/spaces" className="btn_booking btn-black text-center items-center justify-center">
              ค้นหา space ที่เหมาะกับคุณ
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}
