import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { space, exten_day, master_data } from "utils/services/api/schemas";
import { Loading } from "utils/services/store";
import moment from "moment/moment";
import history from "utils/history";
import CommonServives from "utils/services/api/common";
import gsap from "gsap";

export default function DetailFunction(props) {
  const { setIsLoading } = useContext(Loading);

  const [detailData, setDetailData] = useState({});
  const [masterData, setMasterData] = useState({});
  const [selectdRange, setSelectdRange] = useState(0);
  const [sumOrderPrice, setSumOrderPrice] = useState(0);
  const [sumOrderHours, setSumOrderHours] = useState(0);
  const [resultParams, setResultParams] = useState(0);
  const [sumOrderProcessPrice, setSumOrderProcessPrice] = useState(false);
  const [selectedDate, setSelectedDate] = useState(getSelectedDate(history.location.search));
  const [freeSlots, setFreeSlots] = useState([]);
  const { getById, getList } = CommonServives();
  const { id } = useParams();
  const [currPage, setCurrPage] = useState(0);

  useEffect(() => {
    GetSpaceData();
  }, []);

  useEffect(() => {
    calculateSumPrice(freeSlots);
  }, [freeSlots]);

  function calculateSumPrice(freeSlots = []) {
    let sumPrice = 0;
    let sumHours = 0;
    let sumProcessPrice = 0;

    freeSlots.forEach((slot) => {
      if (slot.selected_range) {
        sumPrice = sumPrice + slot.selected_range.sumPrice;
        sumProcessPrice = sumProcessPrice + slot.selected_range.value.price_process;
        sumHours = sumHours + slot.selected_range.hours;
        setSelectdRange(true);
      }
    });

    setSumOrderHours(sumHours);
    setSumOrderPrice(sumPrice);
    setSumOrderProcessPrice(sumProcessPrice);
  }

  function getSelectedDate(query) {
    let queryDate = query.split("selected_date=");

    if (queryDate.length > 1) {
      queryDate = queryDate[1];
    } else {
      queryDate = null;
    }

    return queryDate;
  }

  function getSumpriceOfDateRange(rang_price, startTime, endTime) {
    let sumPrice = 0;
    rang_price.forEach((range) => {
      if (moment(range.time, "HH:mm") >= startTime && moment(range.time, "HH:mm") < endTime) {
        sumPrice = sumPrice + range.price;
      }
    });

    return sumPrice;
  }

  function onSelectDate(date, index) {
    const freeSlotIndex = freeSlots.findIndex((slot) => slot.index === index);
    if (freeSlotIndex !== undefined && date !== freeSlots[freeSlotIndex].selected_date) {
      setIsLoading(true);
      getById(exten_day, `${id}?selected_date=${date}`)
        .then((response) => {
          let free_slots = [...freeSlots];
          free_slots[freeSlotIndex].free_slots = response.data.free_slots;
          free_slots[freeSlotIndex].selected_date = date;
          free_slots[freeSlotIndex].selected_range = null;
          setFreeSlots(free_slots);
          setIsLoading(false);
        })
        .catch((err) => {
          alert(err);
          setIsLoading(false);
        });
    }
  }

  function onSelectTimeRange(range, index) {
    // diff of time
    const freeSlotIndex = freeSlots.findIndex((slot) => slot.index === index);

    if (freeSlotIndex !== undefined) {
      let free_slots = [...freeSlots];
      const isNextDayOfTime = moment(range.value.start, "HH:mm") > moment(range.value.end, "HH:mm");
      const startTime = moment(range.value.start, "HH:mm");
      const endTime = moment(range.value.end, "HH:mm").add(isNextDayOfTime ? 1 : 0, "d");
      const day = moment(free_slots[freeSlotIndex].selected_date).format("dddd").toLocaleLowerCase();
      const diff = endTime.diff(startTime, "hours");
      const findADay = detailData.days.find((item) => item.day === day);
      let sumPrice = getSumpriceOfDateRange(findADay.rank_price, startTime, endTime);
      let newParams = { ...range, hours: diff, sumPrice: sumPrice };

      free_slots[freeSlotIndex].selected_range = newParams;

      setFreeSlots(free_slots);
    }
  }

  function onAddedExtended() {
    let free_slots = [...freeSlots];
    let ranIndex = Math.round(Math.random() * 999 + free_slots.length);
    free_slots.push({ index: ranIndex, selected_date: "", free_slots: [], selected_range: null });
    setFreeSlots(free_slots);
  }

  function onDeleteExtended() {
    let free_slots = [...freeSlots];
    free_slots.splice(free_slots.length - 1, 1);
    setFreeSlots(free_slots);
  }

  function setResultStructure(params) {
    setResultParams(params);
  }

  function onChangePage(page, isBack = false) {
    if (isBack === false) {
      let isError = false;
      freeSlots.forEach((slot) => {
        if (!slot.selected_date || !slot.selected_range) {
          isError = true;
          return;
        }
      });

      if (isError) {
        alert("กรุณาเลือกวันที่ และช่วงเวลาให้ครบถ้วน");
      } else {
        setIsLoading(true);
        gsap.to("section", {
          duration: 1,
          opacity: 0,
          delay: 0,
          ease: "power3.out",
        });
        setTimeout(() => {
          setTimeout(() => {
            setIsLoading(false);
          }, 1000);
          setCurrPage(page);
        }, 1000);
      }
    } else {
      setIsLoading(true);
      gsap.to("section", {
        duration: 1,
        opacity: 0,
        delay: 0,
        ease: "power3.out",
      });
      setTimeout(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 3000);
        setCurrPage(page);
      }, 1000);
    }
  }

  function GetSpaceData() {
    setIsLoading(true);
    let device = window.innerWidth <= 640 ? "mobile" : window.innerWidth <= 1024 ? "tablet" : "desktop";
    getById(`${space}`, `${id}${selectedDate && selectedDate.length > 1 ? `?selected_date=${selectedDate}` : `?device=${device}`}`)
      .then((response) => {
        let free_slots = [...freeSlots];
        let ranIndex = Math.round(Math.random() * 999 + free_slots.length);

        if (selectedDate && response.data.free_slots) {
          free_slots.push({ index: ranIndex, selected_date: selectedDate, free_slots: response.data.free_slots, selected_range: null });
          setFreeSlots(free_slots);
        } else {
          const isRedirect = window.location.search && window.location.search.split("currentPage=").length > 1;
          const bookData = JSON.parse(localStorage.getItem("book_data"));
          if (bookData && isRedirect) {
            setFreeSlots(bookData);
          } else {
            localStorage.clear();
            free_slots.push({ index: ranIndex, selected_date: "", free_slots: [], selected_range: null });
            setFreeSlots(free_slots);
          }
        }

        console.log("response.data", response.data);

        GetMasterData();
        setDetailData(response.data);
      })
      .catch((err) => {
        alert(err);
        window.location.href = "/spaces";
        setIsLoading(false);
      });
  }

  function GetMasterData() {
    getList(master_data)
      .then((response) => {
        setMasterData(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        alert(err);
        window.location.href = "/spaces";
        setIsLoading(false);
      });
  }

  function findDuplicateSelected(freeSlots = [], freeSlot, time) {
    let isDuplicate = false;

    freeSlots.forEach((slot) => {
      if (slot.index !== freeSlot.index && slot.selected_date === freeSlot.selected_date && slot.selected_range) {
        let isNextDayOfTime = moment(time.start, "HH:mm") > moment(time.end, "HH:mm");
        let isNextDayOfTimeSelect = moment(slot.selected_range.value.start, "HH:mm") > moment(slot.selected_range.value.end, "HH:mm");

        if (
          (moment(`${freeSlot.selected_date} ${time.start}`, "MM-DD-YYYY HH:mm") <= moment(`${freeSlot.selected_date} ${slot.selected_range.value.start}`, "MM-DD-YYYY HH:mm") &&
            moment(`${freeSlot.selected_date} ${slot.selected_range.value.start}`, "MM-DD-YYYY HH:mm") < moment(`${freeSlot.selected_date} ${time.end}`, "MM-DD-YYYY HH:mm").add(isNextDayOfTime ? 1 : 0, "d")) ||
          (moment(`${freeSlot.selected_date} ${time.start}`, "MM-DD-YYYY HH:mm") >= moment(`${freeSlot.selected_date} ${slot.selected_range.value.start}`, "MM-DD-YYYY HH:mm") &&
            moment(`${freeSlot.selected_date} ${time.end}`, "MM-DD-YYYY HH:mm").add(isNextDayOfTime ? 1 : 0, "d") < moment(`${freeSlot.selected_date} ${slot.selected_range.value.end}`, "MM-DD-YYYY HH:mm").add(isNextDayOfTimeSelect ? 1 : 0, "d")) ||
          (moment(`${freeSlot.selected_date} ${time.start}`, "MM-DD-YYYY HH:mm") >= moment(`${freeSlot.selected_date} ${slot.selected_range.value.start}`, "MM-DD-YYYY HH:mm") &&
            moment(`${freeSlot.selected_date} ${time.start}`, "MM-DD-YYYY HH:mm") < moment(`${freeSlot.selected_date} ${slot.selected_range.value.end}`, "MM-DD-YYYY HH:mm"))
        ) {
          isDuplicate = true;
        }
      }

      return false;
    });

    return isDuplicate;
  }

  function onChangeExpectArriavalTime(e, index) {
    const freeSlotIndex = freeSlots.findIndex((slot) => slot.index === index);

    if (freeSlotIndex !== undefined) {
      let free_slots = [...freeSlots];
      free_slots[freeSlotIndex].note = e;
      setFreeSlots(free_slots);
    }
  }

  return {
    resultParams,
    currPage,
    masterData,
    sumOrderHours,
    sumOrderPrice,
    sumOrderProcessPrice,
    detailData,
    freeSlots,
    setFreeSlots,
    selectdRange,
    selectedDate,
    GetSpaceData,
    onSelectTimeRange,
    onSelectDate,
    onAddedExtended,
    onDeleteExtended,
    onChangePage,
    findDuplicateSelected,
    setResultStructure,
    onChangeExpectArriavalTime,
    setCurrPage,
  };
}
