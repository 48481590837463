import React, { useEffect, useContext } from "react";
import liff from "@line/liff";
import { LineLogin } from "utils/services/store";

export default function LineContainer() {
  useEffect(() => {
    liff
      .init({
        liffId: process.env.REACT_APP_LINE_LIFF_ID, // Use own liffId
      })
      .then(async () => {
        if (!liff.isLoggedIn()) {
          liff.login();
        } else {
          let profile = await liff.getProfile();
          let book_id = localStorage.getItem("book_id");

          if (window.opener) {
            window.opener.postMessage(profile, "*");
            window.close();
          } else {
            window.location.href = `${process.env.REACT_APP_BASE_URL}/spaces/${book_id}?currentPage=1`;
          }

          localStorage.setItem("line_profile", JSON.stringify(profile));
          // setLineLoginData(profile)

          alert("Login with : " + profile.displayName + ", Please close this page and go back to your browser.");
        }
      })
      .catch((err) => {
        alert(err);
      });
  }, []);
  return <div style={{ display: "flex", width: "100%", height: "100vh", alignItems: "center", justifyContent: "center", fontSize: 18, fontWeight: "bold" }}>Please Wait...</div>;
}
